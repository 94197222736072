import React, { useState, useMemo, useCallback } from 'react';
import {
  Button,
  Modal,
  Divider,
  Feed,
  Card,
  Segment,
  Loader,
  Label,
} from 'semantic-ui-react';
import { useQuery } from '@apollo/client';
import { ItemCard } from 'components';

import { EVENTS_QUERY } from 'gql';

const PER = 20;

const EventsContent = () => {
  const { data = {}, loading, fetchMore } = useQuery(EVENTS_QUERY, {
    variables: { per: PER },
  });
  const { exoEvents = {} } = data;
  const { nodes = [], page, per, total } = exoEvents;
  const isNextPage = total > per * page;

  const events = useMemo(
    () =>
      nodes.map((e) => {
        const extraData = {
          packages_arrived: {
            image:
              'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Package.svg/1200px-Package.svg.png',
            summary: (
              <>
                Package <a>{e.eventItems[0]?.item?.packageTracking}</a> has
                arrived to USA warehouse containing <a>{e.eventItems.length}</a>{' '}
                items
              </>
            ),
          },
          items_shipped: {
            image:
              'https://p7.hiclipart.com/preview/275/315/772/travel-agent-computer-icons-flight-travel.jpg',
            summary: (
              <>
                <a>{e.eventItems.length}</a> Items were shipped from <a>USA</a>{' '}
                to <a>Egypt</a> warehouse
              </>
            ),
          },
          items_received: {
            image:
              'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSu7nrPWfd2XaY3QxX1RJxdWMOTzPP7GOIMYQ&usqp=CAU',
            summary: (
              <>
                <a>{e.eventItems.length}</a> Items received at <a>Cairo</a>{' '}
                warehouse
              </>
            ),
          },
          orders_out_for_delivery: {
            image:
              'https://www.shareicon.net/data/512x512/2016/05/27/771325_transport_512x512.png',
            summary: (
              <>
                Order <a>#123456</a> is out for delivery
              </>
            ),
          },
          orders_delivered: {
            image:
              'https://www.kingmaterialshandling.com/wp-content/uploads/2019/09/completed-icon-6.png',
            summary: (
              <>
                Order <a>#123456</a> is out for delivery
              </>
            ),
          },
        }[e.eventType];

        return {
          ...e,
          ...extraData,
        };
      }),
    [nodes]
  );

  const renderEvent = useCallback((e) => {
    return (
      <Feed.Event key={e.id}>
        <Feed.Label>
          <img alt="" src={e.image} />
        </Feed.Label>
        <Feed.Content>
          <Feed.Date>{e.date}</Feed.Date>
          <Feed.Summary>{e.summary}</Feed.Summary>
          <Feed.Extra>
            <Card.Group itemsPerRow={5} stackable>
              {e.eventItems.map((eventItem) => {
                if (eventItem.itemType === 'item') {
                  return (
                    <ItemCard simple key={eventItem.id} item={eventItem.item} />
                  );
                }

                return null;
              })}
            </Card.Group>
          </Feed.Extra>
          <Divider hidden />
          <Divider />
        </Feed.Content>
      </Feed.Event>
    );
  }, []);

  return (
    <>
      {loading && (
        <Label
          style={{ position: 'fixed', right: '1.25rem', top: '1.25rem' }}
          circular
          color="grey"
        >
          <Loader size="mini" inverted inline />
        </Label>
      )}

      {!!events.length ? (
        <Feed>{events.map(renderEvent)}</Feed>
      ) : (
        <h3 style={{ marginTop: '3rem', textAlign: 'center' }}>
          You have no activities yet.
        </h3>
      )}

      {isNextPage && (
        <Segment basic textAlign="center">
          <Button
            size="large"
            onClick={() =>
              fetchMore({
                variables: { per: PER, page: page + 1 },
              })
            }
          >
            Load more
          </Button>
        </Segment>
      )}
    </>
  );
};

const Comp = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <Modal
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      // trigger={<Button color="blue">Live Activities</Button>}
      size="small"
      style={{ minHeight: '76vh' }}
      {...props}
    >
      <Modal.Header>Live Activities</Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>{open && <EventsContent />}</Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default Comp;
