import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';
import '@stripe/stripe-js';

// import 'semantic-ui-css/semantic.min.css';
import './assets/styles/semantic.css';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import './assets/styles/global-style.css';

import App from 'screens';
import { AuthProvider, MediaProvider } from 'providers';
import { apolloClient, reportWebVitals } from 'config';

const rollbarConfig = {
  accessToken: '5446b7ce423b4c9a990fac98c36147a0',
  enabled: process.env.NODE_ENV === 'production',
  captureUncaught: true,
  captureUnhandledRejections: true,
  logLevel: 'info',
  reportLevel: 'error',
  payload: {
    environment: process.env.NODE_ENV,
    client: {
      javascript: {
        environment: process.env.NODE_ENV,
        code_version: process.env.REACT_APP_GIT_SHA,
        source_map_enabled: true,
        guess_uncaught_frames: true,
      },
    },
  },
};

ReactDOM.render(
  <RollbarProvider config={rollbarConfig}>
    <ErrorBoundary>
      <ApolloProvider client={apolloClient}>
        <BrowserRouter>
          <AuthProvider>
            <MediaProvider>
              <App />
            </MediaProvider>
          </AuthProvider>
        </BrowserRouter>
      </ApolloProvider>
    </ErrorBoundary>
  </RollbarProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
