import { gql } from '@apollo/client';

export default gql`
  fragment ExoItemFragment on ExoItem {
    id
    orderId
    packageTracking
    photo {
      url
      url_xsmall
      url_small
      url_medium
    }
    weight
    price
    priceUsd
    categoryName
    state
    maxDeliveryDate
    minDeliveryDate
    arrivedAtCairoOfficeAt
    sourceCountry
    shippingType
  }
`;
