import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment,
} from 'semantic-ui-react';

import { useError } from 'hooks';
import { useAuth } from 'providers';
import { routes } from 'config';
import { LOGIN_MUTATION } from 'gql';

import logoImg from 'assets/images/logo-blue.svg';

const Comp = () => {
  const { signIn } = useAuth();

  const history = useHistory();
  const location = useLocation();

  const { redirectLocation } = location.state || {
    redirectLocation: { pathname: routes.signedInHome },
  };

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [loginMtn, { loading, error }] = useMutation(LOGIN_MUTATION, {
    onError: () => null,
    variables: { email, password },
    onCompleted: (data) => {
      signIn(data.login, () => history.replace(redirectLocation));
    },
  });

  const errorMsg = useError(error);

  return (
    <Grid centered style={{ height: '100vh' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <div style={{ textAlign: 'center' }}>
          <Link to="/">
            <Image verticalAlign="middle" centered src={logoImg} />
          </Link>
        </div>
        <Header as="h2" textAlign="center">
          Sign-in to your account
        </Header>
        <Form
          size="large"
          loading={loading}
          error={!!errorMsg}
          onSubmit={loginMtn}
        >
          <Segment stacked>
            <Form.Input
              required
              fluid
              icon="user"
              iconPosition="left"
              label="E-mail address"
              placeholder="E-mail address"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Form.Input
              required
              fluid
              icon="lock"
              iconPosition="left"
              label="Password"
              placeholder="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <Message error content={errorMsg} />

            <Button primary fluid size="large">
              Login
            </Button>
            <br />
            <div style={{ textAlign: 'right' }}>
              <Link
                to={{
                  pathname: routes.forgetPassword,
                  state: { redirectLocation },
                }}
              >
                Forgot Password?
              </Link>
            </div>
          </Segment>
        </Form>
        <Message style={{ textAlign: 'center' }}>
          New to Exohub?{' '}
          <Link to={{ pathname: routes.signUp, state: { redirectLocation } }}>
            Sign Up
          </Link>
        </Message>
      </Grid.Column>
    </Grid>
  );
};

export default Comp;
