import { gql } from '@apollo/client';

export default gql`
  query exoCustomsCategories {
    exoCustomsCategories(iso_code: "EG") {
      id
      name
    }
  }
`;
