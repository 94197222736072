import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Menu, Button, Icon } from 'semantic-ui-react';

import { routes } from 'config';
import { useMedia } from 'providers';

import logoImg from 'assets/images/logo-blue.svg';

const LayoutHeader = ({ menuItems, setSidebarVisible }) => {
  const { isTabletOrMobile } = useMedia();

  return (
    <>
      <Menu
        size="large"
        fixed="top"
        secondary
        style={{
          backgroundColor: '#fff',
          boxShadow: '0 1px 2px 0 rgba(34,36,38,.15)',
        }}
      >
        <Container
          style={{ justifyContent: isTabletOrMobile ? 'center' : undefined }}
        >
          {isTabletOrMobile && (
            <div style={{ position: 'absolute', left: '12px', top: '12px' }}>
              <Button basic icon onClick={() => setSidebarVisible(true)}>
                <Icon name="bars" />
              </Button>
            </div>
          )}

          <Menu.Item>
            <Link to={routes.home}>
              <img
                alt="ExoHub"
                style={{ height: '2.5rem', marginTop: '4px' }}
                src={logoImg}
              />
            </Link>
          </Menu.Item>

          {!isTabletOrMobile && (
            <Menu.Menu position="right">{menuItems}</Menu.Menu>
          )}
        </Container>
      </Menu>

      <div style={{ height: '60px' }} />
    </>
  );
};

export default LayoutHeader;
