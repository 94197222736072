import { gql } from '@apollo/client';
import EXO_ORDER_FRAGMENT from '../f/exoOrder';

export default gql`
  query exoOrder($id: ID!) {
    exoOrder(id: $id) {
      ...ExoOrderFragment
    }
  }
  ${EXO_ORDER_FRAGMENT}
`;
