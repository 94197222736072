import { gql } from '@apollo/client';
import EXO_ITEM_FRAGMENT from '../f/exoItem';

export default gql`
  query exoItems($state: String!, $per: Int, $page: Int) {
    exoItems(state: $state, per: $per, page: $page) {
      nodes {
        ...ExoItemFragment
      }
      page
      per
      total
    }
  }
  ${EXO_ITEM_FRAGMENT}
`;
