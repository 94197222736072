import { gql } from '@apollo/client';
import EXO_ITEM_FRAGMENT from './exoItem';
import ADDRESS_FRAGMENT from '../f/address';

export default gql`
  fragment ExoOrderFragment on ExoOrder {
    id
    items {
      ...ExoItemFragment
    }
    subtotal
    delivery
    adjustments
    total
    totalDue
    placedAt
    outForDeliveryAt
    completedAt
    shippingAddress {
      ...AddressFragment
    }
    state
    paymentMethod
    walletPaymentAmount
    courierName
    trackingNumber
    trackingUrl
  }
  ${EXO_ITEM_FRAGMENT}
  ${ADDRESS_FRAGMENT}
`;
