import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
  Card,
  Item,
  Label,
  List,
  Header,
  Button,
  Icon,
  Divider,
} from 'semantic-ui-react';

import { humanize, routes, dateFormate } from 'config';

import ItemCard from 'components/ItemCard';
import { asCurrency } from 'config';

const Comp = ({ order, full }) => {
  const history = useHistory();

  const isWaitingForPayment = order.state === 'waiting_for_payment';
  const isCompleted = order.state === 'completed';

  let color;
  if (isWaitingForPayment) color = 'blue';
  if (isCompleted) color = 'green';

  const renderAddress = (address) =>
    !address ? null : (
      <Item.Group>
        <Item>
          <Item.Content className="textLeft">
            <Item.Meta>
              <List horizontal size="small">
                <List.Item>
                  <b>
                    <Icon name="user" /> {address.full_name}
                  </b>
                </List.Item>
                <List.Item>
                  <b>
                    <Icon name="phone" /> {address.phone_number?.intl_number}
                  </b>
                </List.Item>
              </List>
            </Item.Meta>
            <Item.Description>
              {address.street_number}, {address.apartment_floor}
              <Divider hidden fitted />
              <small>
                {address.city?.name} - {address.city?.country?.name}
              </small>
            </Item.Description>
          </Item.Content>
        </Item>
      </Item.Group>
    );

  return (
    <Card fluid={full}>
      <Card.Content>
        <Card.Header>
          <Label color={color} attached="top right">
            {humanize(order.state)}
          </Label>
          Order #{order.id}
        </Card.Header>
        {!full && order.placedAt && (
          <Card.Meta>
            Placed at: <b>{dateFormate(order.placedAt, 'E, do MMM, yyyy')}</b>
          </Card.Meta>
        )}
        {full && <Card.Meta>{renderAddress(order.shippingAddress)}</Card.Meta>}
      </Card.Content>

      {order.trackingNumber && (
        <Card.Content>
          <List verticalAlign="middle">
            <List.Item>
              <List.Content floated="right">{order.courierName}</List.Content>
              <List.Content>Courier:</List.Content>
            </List.Item>
            <List.Item>
              <List.Content floated="right">
                {order.trackingNumber}
              </List.Content>
              <List.Content>Tracking Number:</List.Content>
            </List.Item>
          </List>
          <List.Item>
            <List.Content floated="right">
              <Button
                as="a"
                href={order.trackingUrl}
                target="_blank"
                primary
                // fluid
                size="small"
                icon
                labelPosition="right"
              >
                Track Your Order
                <Icon name="location arrow" />
              </Button>
            </List.Content>
            <List.Content></List.Content>
          </List.Item>
          {/* <div style={{ textAlign: 'center' }}>
            <Button
              as={Link}
              to={routes.order(order)}
              primary
              // fluid
              size="large"
              icon
              labelPosition="right"
            >
              Track Your Order
              <Icon name="user" />
            </Button>
          </div> */}
        </Card.Content>
      )}

      {!full && (
        <Card.Content>
          <Card.Meta>
            Items count: <b>{order.items.length}</b>
          </Card.Meta>
          <Card.Description>
            <Card.Group itemsPerRow={3} stackable>
              {order.items.slice(0, 3).map((item) => (
                <ItemCard key={item.id} item={item} simple />
              ))}
            </Card.Group>
          </Card.Description>
        </Card.Content>
      )}

      {full && (
        <Card.Content>
          <List verticalAlign="middle">
            {order.placedAt && (
              <List.Item>
                <List.Content floated="right">
                  {dateFormate(order.placedAt, 'E, do MMM, yyyy')}
                </List.Content>
                <List.Content>Placed at:</List.Content>
              </List.Item>
            )}
            {order.outForDeliveryAt && (
              <List.Item>
                <List.Content floated="right">
                  {dateFormate(order.outForDeliveryAt, 'E, do MMM, yyyy')}
                </List.Content>
                <List.Content>Out for delivery at:</List.Content>
              </List.Item>
            )}
            {order.completedAt && (
              <List.Item>
                <List.Content floated="right">
                  {dateFormate(order.completedAt, 'E, do MMM, yyyy')}
                </List.Content>
                <List.Content>Completed at:</List.Content>
              </List.Item>
            )}
          </List>
        </Card.Content>
      )}

      <Card.Content>
        <List verticalAlign="middle">
          {full && (
            <>
              <List.Item>
                <List.Content floated="right">
                  <Header size="small">{asCurrency(order.subtotal)} EGP</Header>
                </List.Content>
                <List.Content>
                  <Header size="small">Subtotal</Header>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content floated="right">
                  <Header size="small">{asCurrency(order.delivery)} EGP</Header>
                </List.Content>
                <List.Content>
                  <Header size="small">Delivery</Header>
                </List.Content>
              </List.Item>
              {order.adjustments !== 0 && (
                <List.Item>
                  <List.Content floated="right">
                    <Header color="green" size="small">
                      {asCurrency(order.adjustments)} EGP
                    </Header>
                  </List.Content>
                  <List.Content>
                    <Header size="small">Discount</Header>
                  </List.Content>
                </List.Item>
              )}
            </>
          )}
          <List.Item>
            <List.Content floated="right">
              <Header size="small">{asCurrency(order.total)} EGP</Header>
            </List.Content>
            <List.Content>
              <Header size="small">Total</Header>
            </List.Content>
          </List.Item>
          {order.walletPaymentAmount > 0 && (
            <List.Item>
              <List.Content floated="right">
                <Header size="small">
                  {asCurrency(order.walletPaymentAmount)} EGP
                </Header>
              </List.Content>
              <List.Content>
                <Header size="small">Wallet Credit Applied</Header>
              </List.Content>
            </List.Item>
          )}
          {order.totalDue > 0 && (
            <List.Item>
              <List.Content floated="right">
                <Header size="small">{asCurrency(order.totalDue)} EGP</Header>
              </List.Content>
              <List.Content>
                <Header size="small">Total Due</Header>
              </List.Content>
            </List.Item>
          )}
        </List>

        {order.totalDue > 0 && (
          <Button
            fluid
            size="large"
            icon
            labelPosition="right"
            primary
            onClick={() =>
              history.push(routes.checkout, {
                orderId: order.id,
              })
            }
            style={{ marginBottom: '0.5rem' }}
          >
            Change Payment Method
          </Button>
        )}

        {!full && (
          <Button
            as={Link}
            to={routes.order(order)}
            fluid
            size="large"
            icon
            labelPosition="right"
          >
            More Details
          </Button>
        )}
      </Card.Content>
    </Card>
  );
};

export default Comp;
