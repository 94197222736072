import { gql } from '@apollo/client';
import USER_FRAGMENT from '../f/user';

export default gql`
  query user {
    user {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;
