import { gql } from '@apollo/client';
import EXO_CHECKOUT_FRAGMENT from '../f/exoCheckout';

export default gql`
  query exoCheckout($itemIds: [Int], $orderId: Int) {
    exoCheckout(itemIds: $itemIds, orderId: $orderId) {
      ...ExoCheckoutFragment
    }
  }
  ${EXO_CHECKOUT_FRAGMENT}
`;
