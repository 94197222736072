const errorsMap = {
  'Failed to fetch':
    'Can not reach out to our servers, please try again later.',
};

const useError = (gqlError) => {
  if (!gqlError) return;

  console.log('gqlError', JSON.stringify(gqlError, null, 2));

  if (gqlError.networkError?.statusCode === 500)
    return 'Unexpected server error (500).';

  if (!Object.keys(errorsMap).includes(gqlError.message))
    return gqlError.message;

  return errorsMap[gqlError.message];
};

export default useError;
