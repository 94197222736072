import React, { useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

const stripeApiKey =
  process.env.NODE_ENV === 'production'
    ? 'pk_live_EwOU3eIGnp2MYl75QbOSI85z'
    : 'pk_test_i3wsPTYKf6ylQV0cSk86dlda';

const stripePromise = loadStripe(stripeApiKey);

const CardElementComp = ({ getTokenHandler = () => null }) => {
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (!stripe || !elements) return;

    const tokenHandler = async () => {
      const cardElement = elements.getElement(CardElement);
      const res = await stripe.createToken(cardElement);
      if (res.token) {
        return { token: res.token.id };
      }
      return res;
    };

    getTokenHandler(tokenHandler);
  }, [elements, getTokenHandler, stripe]);

  return (
    <CardElement
      options={{
        iconStyle: 'solid',
        style: {
          base: {
            // iconColor: '#c4f0ff',
            // color: '#fff',
            fontSize: '16px',
          },
          invalid: {
            // iconColor: '#FFC7EE',
            // color: '#FFC7EE',
          },
        },
      }}
    />
  );
};

const Comp = (props) => (
  <Elements stripe={stripePromise}>
    <CardElementComp {...props} />
  </Elements>
);

export default Comp;
