import React from 'react';
import { Card, Image, Divider } from 'semantic-ui-react';

import Amazon from './icons/amazon.png';
// import Target from './icons/t.png';
import SixPM from './icons/6pm.png';
import Walmart from './icons/walmart.png';

import Macys from './icons/macys.png';
import Ralph from './icons/rl.png';
import F21 from './icons/f21.png';
import Mk from './icons/mk.png';

import DrugStore from './icons/drugstore.png';
// import BH from './icons/bhh.png';
import Mac from './icons/mac.png';
import Zappos from './icons/zappos.png';

// import NewEgg from './icons/newegg.png';
// import BestBuy from './icons/bestbuy.png';
import BB from './icons/bb.png';
import BHCosmetics from './icons/bhcosmetics.png';

const listIcon = {
  width: '120px',
  height: '70px',
};

const sixPmIcon = {
  width: '120px',
  height: '70px',
  marginTop: '-30px',
  marginBottom: '30px',
};

const Comp = () => {
  // const { user } = useAuth();

  return (
    <>
      <Card.Group itemsPerRow={3} centered stackable>
        <Card style={{ backgroundColor: '#ddfad8' }}>
          <Card.Content>
            <Card.Header>You do not have items here yet.</Card.Header>
            <Card.Description>
              Expecting a shipment to be delivered today? Please allow up to 1
              business day for the shipment to be sorted, photographed and added
              to your locker.
            </Card.Description>
          </Card.Content>
        </Card>
        <Card style={{ backgroundColor: '#d8e9fa' }}>
          <Card.Content>
            <Card.Header>How to shop</Card.Header>
            <Card.Description>
              Shop from any online store then use your Exohub shipping address
              in the checkout step. Once your package is received in Exohub's
              warehouse, it will be shown in this dashboard.
            </Card.Description>
          </Card.Content>
        </Card>
      </Card.Group>

      <h1 style={{ marginTop: '3rem', textAlign: 'center' }}>
        Shop from the best online stores around the world
      </h1>

      <Card.Group itemsPerRow={4} centered stackable>
        <Card style={{ textAlign: 'center' }}>
          <Card.Content>
            <Card.Header>All</Card.Header>
            <br />
            <Card.Description>
              <Image
                as="a"
                style={listIcon}
                src={Macys}
                target="_blank"
                href="https://www.macys.com/"
              />
              <Divider hidden />
              <Image
                as="a"
                style={listIcon}
                src={Amazon}
                target="_blank"
                href="https://www.amazon.com"
              />
              <Divider hidden />
              <Image
                as="a"
                style={sixPmIcon}
                src={SixPM}
                target="_blank"
                href="https://www.6pm.com"
              />
              <Divider hidden />
              <Image
                as="a"
                style={listIcon}
                src={Walmart}
                target="_blank"
                href="https://https://www.walmart.com/"
              />
            </Card.Description>
          </Card.Content>
        </Card>

        <Card style={{ textAlign: 'center' }}>
          <Card.Content>
            <Card.Header>Fashion</Card.Header>
            <br />
            <Card.Description>
              <Image
                as="a"
                style={listIcon}
                src={Mk}
                target="_blank"
                href="https://www.michaelkors.com/"
              />
              <Divider hidden />
              <Image
                as="a"
                style={listIcon}
                src={Ralph}
                target="_blank"
                href="https://www.ralphlauren.com/"
              />
              <Divider hidden />
              <Image
                as="a"
                style={listIcon}
                src={F21}
                target="_blank"
                href="https://www.forever21.com/us/shop"
              />
              <Divider hidden />
              <Image
                as="a"
                style={listIcon}
                src={Zappos}
                target="_blank"
                href="https://www.zappos.com/"
              />
            </Card.Description>
          </Card.Content>
        </Card>

        <Card style={{ textAlign: 'center' }}>
          <Card.Content>
            <Card.Header>Health & Beauty</Card.Header>
            <br />
            <Card.Description>
              <Image
                as="a"
                style={listIcon}
                src={Mac}
                target="_blank"
                href="https://www.maccosmetics.com"
              />
              <Divider hidden />
              <Image
                as="a"
                style={listIcon}
                src={BHCosmetics}
                target="_blank"
                href="https://www.bhcosmetics.com/"
              />
              <Divider hidden />
              <Image
                as="a"
                style={listIcon}
                src={DrugStore}
                target="_blank"
                href="https://www.theonlinedrugstore.com"
              />
              <Divider hidden />
              <Image
                as="a"
                style={listIcon}
                src={BB}
                target="_blank"
                href="https://www.bodybuilding.com/"
              />
            </Card.Description>
          </Card.Content>
        </Card>
      </Card.Group>
    </>
  );
};

export default Comp;
