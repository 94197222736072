import { gql } from '@apollo/client';
import EXO_ITEM_FRAGMENT from './exoItem';

export default gql`
  fragment ExoEventFragment on ExoEvent {
    id
    date
    eventType
    message
    eventItems {
      id
      itemType
      item {
        ...ExoItemFragment
      }
    }
  }
  ${EXO_ITEM_FRAGMENT}
`;
