import { gql } from '@apollo/client';
import EXO_ORDER_FRAGMENT from '../f/exoOrder';

export default gql`
  query exoOrders($per: Int, $page: Int) {
    exoOrders(per: $per, page: $page) {
      nodes {
        ...ExoOrderFragment
      }
      page
      per
      total
    }
  }
  ${EXO_ORDER_FRAGMENT}
`;
