import React, { useEffect } from 'react';
import { Loader } from 'semantic-ui-react';
import { useAuth } from 'providers';

const Comp = () => {
  const { signOut } = useAuth();

  useEffect(() => {
    signOut();
  }, [signOut]);

  return <Loader active size="large" />;
};

export default Comp;
