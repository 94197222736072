import React, { useState, useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useMutation } from '@apollo/client';
import Compress from 'compress.js';

import { Button, Message, Dimmer, Loader, Segment } from 'semantic-ui-react';

import { useAuth } from 'providers';
import { UPLOAD_ID_MUTATION } from 'gql';

const compress = new Compress();

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  // alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  // color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const Comp = () => {
  const { user } = useAuth();

  const [mainLoading, setMainLoading] = useState(false);
  const [selectedFileInfo, setSelectedFileInfo] = useState();

  const [mtnFnc, { loading }] = useMutation(UPLOAD_ID_MUTATION, {
    onError: () => null,
    variables: { imageBaseUri: selectedFileInfo },
  });

  const onDrop = useCallback(async (files) => {
    setMainLoading(true);
    const resizedImg = await compress.compress(files, {
      size: 4, // the max size in MB, defaults to 2MB
      quality: 0.75, // the quality of the image, max is 1,
      maxWidth: 3000, // the max width of the output image, defaults to 1920px
      maxHeight: 3000, // the max height of the output image, defaults to 1920px
      resize: true, // defaults to true, set false if you do not want to resize the image width and height
    });
    console.log('resizedImg[0].prefix', resizedImg[0].prefix);
    setSelectedFileInfo(`${resizedImg[0].prefix}${resizedImg[0].data}`);
    setMainLoading(false);
  }, []);

  // const onDrop = useCallback(([file]) => {
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);

  //   reader.onload = () => {
  //     const fileInfo = {
  //       name: file.name,
  //       type: file.type,
  //       size: Math.round(file.size / 1000) + ' kB',
  //       base64: reader.result,
  //       file: file,
  //     };
  //     setSelectedFileInfo(fileInfo);

  //     console.log('fileInfo', JSON.stringify(fileInfo));
  //   };
  // }, []);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: 'image/*', onDrop, maxFiles: 1 });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  if (user.exohubAccountState === 'unverified')
    return (
      <Message compact info>
        <h4 style={{ marginTop: 0, textAlign: 'left' }}>
          Your Document has been submitted.
        </h4>
        It usually takes up to 1 business day to verify your account.
      </Message>
    );

  if (user.exohubAccountState === 'pending')
    return (
      <>
        <Segment basic style={{ padding: 0 }}>
          <Dimmer active={mainLoading} inverted>
            <Loader indeterminate>Preparing File</Loader>
          </Dimmer>
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <h4 style={{ marginTop: 0, textAlign: 'left' }}>
              Account not verified yet!
            </h4>
            <p>
              Please verify your identity first to get your free US address by
              uploading your National ID or Passport.{' '}
              <b>
                You can drag 'n' drop file here, or click to select a file to
                upload.
              </b>
            </p>
          </div>
        </Segment>
        {!!selectedFileInfo && !mainLoading && (
          <>
            <Button
              floated="right"
              style={{ marginTop: '0.5rem' }}
              loading={loading}
              disabled={loading}
              onClick={mtnFnc}
            >
              Submit
            </Button>
            <ul>
              {acceptedFiles.map((file) => (
                <li key={file.path}>{file.path}</li>
              ))}
            </ul>
          </>
        )}
      </>
    );

  return null;
};

export default Comp;
