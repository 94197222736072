const routes = {
  home: '/',
  signedInHome: '/dashboard',
  signIn: '/signin',
  signUp: '/signup',
  signOut: '/signout',
  faq: '/faq',
  terms: '/terms-conditions',
  privacyPolicy: '/privacy-policy',
  forgetPassword: '/forget-password',
  resetPassword: '/reset-password',
  dashboard: '/dashboard',
  checkout: '/dashboard/checkout',
  orders: '/account/orders',
  orderCompleted: '/dashboard/checkout/completed',
  contact: '/contact',

  order({ id }) {
    return `${this.orders}/${id}`;
  },
};

export default routes;
