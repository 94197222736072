import React from 'react';
import { Card, Image, Icon, List, Label } from 'semantic-ui-react';

import { humanize, dateFormate, asCurrency } from 'config';

const Comp = ({
  item,
  onSelect,
  onUnselect,
  withState = false,
  simple = false,
  selected,
}) => {
  if (!item) return null;

  const onSelectHandler = () => {
    if (!item.price) return;
    selected ? onUnselect(item) : onSelect(item);
  };

  return (
    <Card
      onClick={
        (!onSelect && !onUnselect) || !item.price ? undefined : onSelectHandler
      }
    >
      <Image
        // src="https://storage.googleapis.com/exohub-warehouse/Xxjxc7NUMHTru9MGJY7U1eRt?GoogleAccessId=261485658549-compute%40developer.gserviceaccount.com&Expires=1761062415&Signature=em53krgiwtqByRuCmWGVdksS3JtaONWWrcutA66q%2Fa3BWB%2F%2F3SEXQc6Qrh%2FYMH5cP%2BjiFLVip%2B9QQNXiAia6U9G0ZWRtEr2zNsGlSIUhtCZgr54Sg0Fv5%2FbYbMa5Rz2P2jzGN%2FcCD9didVAnWCT3HagKO30n5xIM%2BglZtj8hsSMlUul6nGLP0QYwZbSrV3BJniLTIjckBYi4VtrnTZjkQTt0YXNbDED99ZWRKFIZzsGmGPUmZGWqsd4DQ%2BmVk9f5ohrW5plnAQzUeUVGYs4bB0Z4G8Z1GntBxwvzYft%2FNG83CGxx%2FmnLYnVaz6VXwENOcdKAtGIY9kE3tYi%2BRlgYAw%3D%3D&response-content-disposition=inline%3B+filename%3D%22img_1603296014.jpeg%22%3B+filename%2A%3DUTF-8%27%27img_1603296014.jpeg&response-content-type=image%2Fjpeg"
        src={item.photo?.url_medium}
        dimmer={{
          active: selected,
          content: (
            <div>
              <Icon name="check circle" size="big" color="green" />
            </div>
          ),
        }}
      />
      {!simple && (
        <>
          <Card.Content>
            <List verticalAlign="middle">
              <List.Item>
                <List.Content floated="right">
                  {item.sourceCountry}
                </List.Content>
                <List.Content>Source Country:</List.Content>
              </List.Item>
              <List.Item>
                <List.Content floated="right">{item.shippingType}</List.Content>
                <List.Content>Shipping Type:</List.Content>
              </List.Item>
              <List.Item>
                <List.Content floated="right">
                  {item.weight ? `${item.weight}Kg` : '--'}
                </List.Content>
                <List.Content>Weight:</List.Content>
              </List.Item>

              <List.Item>
                <List.Content floated="right">
                  {item.categoryName || '--'}
                </List.Content>
                <List.Content>Category:</List.Content>
              </List.Item>
              <List.Item>
                <List.Content floated="right">
                  {item.priceUsd ? `${asCurrency(item.priceUsd)} USD` : '--'} {item.price ? `(${asCurrency(item.price)} EGP)` : ''}
                </List.Content>
                <List.Content>Price:</List.Content>
              </List.Item>
            </List>
            {withState && <Label color="purple">{humanize(item.state)}</Label>}
            {item.state === 'arrived_at_cairo_office' && !item.price && (
              <div style={{ textAlign: 'center' }}>
                <Label color="red">Contact support for missing data</Label>
              </div>
            )}
          </Card.Content>

          <Card.Content extra>
            <Card.Description>
              <small>
                <b>Item ID: {item.id}</b>
              </small>
            </Card.Description>
            {!simple && (
              <>
                <Card.Description>
                  <small>
                    <b>Pack. Tracking: {item.packageTracking}</b>
                  </small>
                </Card.Description>
                {item.state === 'in_transit_to_cairo' && (
                  <Card.Description>
                    <small>
                      <b>
                        Expected Arrival:{' '}
                        {dateFormate(item.minDeliveryDate, 'd MMM - ')}
                        {dateFormate(item.maxDeliveryDate, 'd MMM, yyyy')}
                      </b>
                    </small>
                  </Card.Description>
                )}
                {item.arrivedAtCairoOfficeAt && (
                  <Card.Description>
                    <small>
                      <b>
                        Arrived at Cairo on:{' '}
                        {dateFormate(
                          item.arrivedAtCairoOfficeAt,
                          'd MMM, yyyy'
                        )}
                      </b>
                    </small>
                  </Card.Description>
                )}
              </>
            )}
          </Card.Content>
        </>
      )}

      {simple && (
        <small style={{ padding: '0.5rem' }}>
          <b>ID: {item.id}</b>
          {!!item.weight && (
            <>
              <br />
              Weight: {item.weight}Kg
            </>
          )}
        </small>
      )}
    </Card>
  );
};

export default Comp;
