import React from 'react';
import { Modal, Form, Button, Input, Dropdown } from 'semantic-ui-react';
import { useQuery, useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { CITIES_QUERY, NEW_ADDRESS_MUTATION, ADDRESSES_QUERY } from 'gql';

const phoneRegExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

const Comp = ({ onNewAddress = () => null, ...modalProps }) => {
  const [mtnFnc, { loading }] = useMutation(NEW_ADDRESS_MUTATION, {
    onError: () => null,
    onCompleted: ({ createAddress }) => {
      onNewAddress(createAddress);
    },
    refetchQueries: [{ query: ADDRESSES_QUERY }],
    awaitRefetchQueries: true,
  });

  const { data = {}, loading: citiesLoading } = useQuery(CITIES_QUERY, {
    variables: { isoCode: 'EG' },
  });
  const { country = {} } = data;
  const { cities = [] } = country;

  const formik = useFormik({
    initialValues: {
      cityId: '',
      fullName: '',
      streetName: '',
      apartmentFloor: '',
      phoneNumber: '',
    },
    validationSchema: Yup.object({
      cityId: Yup.string().required('City is required'),
      fullName: Yup.string().required('Full name is required'),
      streetName: Yup.string().required('Street is required'),
      apartmentFloor: Yup.string().required('Apartment is required'),
      phoneNumber: Yup.string()
        .required('Phone number is required')
        .matches(phoneRegExp, 'Phone number is not valid'),
    }),
    onSubmit: (variables) => {
      console.log(JSON.stringify(variables, null, 2));
      mtnFnc({ variables });
    },
  });

  return (
    <Modal closeIcon closeOnDimmerClick={false} size="small" {...modalProps}>
      <Modal.Header>Add new address</Modal.Header>
      <Modal.Content>
        <Form loading={loading} size="large" onSubmit={formik.handleSubmit}>
          <Form.Group widths="equal">
            <Form.Input
              required
              id="fullName"
              name="fullName"
              onChange={formik.handleChange}
              value={formik.values.fullName}
              label="Full name (Address Recipient)"
              control="input"
              placeholder="Full name"
              error={formik.touched.fullName && !!formik.errors.fullName}
            />

            <Form.Field
              required
              error={formik.touched.phoneNumber && !!formik.errors.phoneNumber}
            >
              <label>Phone Number</label>
              <Input
                id="phoneNumber"
                name="phoneNumber"
                label="+20"
                onChange={formik.handleChange}
                value={formik.values.phoneNumber}
                type="tel"
                control="input"
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Input
              required
              id="streetName"
              name="streetName"
              onChange={formik.handleChange}
              value={formik.values.streetName}
              label="Building Number and Street Name"
              control="input"
              placeholder="Building and street name (19 Talaat Harb St.)"
              error={formik.touched.streetName && !!formik.errors.streetName}
            />

            <Form.Input
              required
              id="apartmentFloor"
              name="apartmentFloor"
              onChange={formik.handleChange}
              value={formik.values.apartmentFloor}
              label="Apartment or Floor Number"
              control="input"
              placeholder="8th Floor, Apt. 100"
              error={
                formik.touched.apartmentFloor && !!formik.errors.apartmentFloor
              }
            />
          </Form.Group>

          <Form.Field required>
            <label>City</label>
            <Dropdown
              value={formik.values.cityId}
              onChange={(e, d) => {
                formik.setFieldValue('cityId', d.value);
                formik.setFieldTouched('cityId', true, false);
              }}
              fluid
              selection
              search
              options={cities.map((c) => ({
                key: c.id,
                value: c.id,
                text: c.name,
              }))}
              placeholder="City"
              loading={citiesLoading}
            />
          </Form.Field>

          <Button type="submit" color="teal" fluid size="large">
            Save Address
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default Comp;
