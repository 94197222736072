import { gql } from '@apollo/client';
import USER_FRAGMENT from '../f/user';

export default gql`
  mutation exoUploadId($imageBaseUri: String!) {
    exoUploadId(imageBaseUri: $imageBaseUri) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;
