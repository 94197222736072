import { gql } from '@apollo/client';
import EXO_ORDER_FRAGMENT from '../f/exoOrder';

export default gql`
  mutation exoNewCheckout(
    $itemIds: [Int]
    $orderId: Int
    $addressId: Int
    $paymentMethod: String
    $paymentDetails: PaymentDetails
  ) {
    exoNewCheckout(
      itemIds: $itemIds
      orderId: $orderId
      addressId: $addressId
      paymentMethod: $paymentMethod
      paymentDetails: $paymentDetails
    ) {
      ...ExoOrderFragment
    }
  }
  ${EXO_ORDER_FRAGMENT}
`;
