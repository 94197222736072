import React, { useState } from 'react';
import { useLocation, Link, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { useRollbarPerson, useRollbar } from '@rollbar/react';
import {
  Header,
  Grid,
  Image,
  Divider,
  Segment,
  Item,
  List,
  Button,
  Icon,
  Message,
  Loader,
  Popup,
} from 'semantic-ui-react';

import { AddressSelector } from 'components';

import { routes } from 'config';
import logoImg from 'assets/images/logo-blue.svg';

import { CHECKOUT_QUERY, NEW_CHECKOUT_MUTATION, ITEMS_QUERY } from 'gql';

import PaymentMethod from './PaymentMethod';
import { asCurrency } from 'config';

import { useAuth } from 'providers';

const Comp = () => {
  const { user } = useAuth();
  useRollbarPerson(user);

  const rollbar = useRollbar();

  const history = useHistory();
  const { state = {} } = useLocation();
  const { itemIds, orderId } = state;

  if (!orderId && (!itemIds || !itemIds.length)) {
    history.replace(routes.home);
  }

  const [selectedAddressId, setSelectedAddressId] = useState();
  const [noAddressError, setNoAddressError] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const [paymentError, setPaymentError] = useState();

  // query
  const variables = { itemIds, orderId: parseInt(orderId) };
  const { data = {}, loading } = useQuery(CHECKOUT_QUERY, {
    variables,
    fetchPolicy: 'network-only',
    onCompleted: ({ exoCheckout }) => {
      const { paymentMethods = [], order = {} } = exoCheckout;
      setSelectedPaymentMethod(order.paymentMethod || paymentMethods[0]);
      setSelectedAddressId(order.shippingAddress?.id);

      rollbar.info('CHECKOUT_QUERY onCompleted', exoCheckout);
    },
  });
  const { exoCheckout = {} } = data;
  const {
    order,
    paymentMethods = [],
    walletPaymentAmount,
    walletRestBalance,
    paymentAmount,
    paymentAmountUsd,
  } = exoCheckout;

  const [mtnFnc, { loading: mtnLoading }] = useMutation(NEW_CHECKOUT_MUTATION, {
    onError: (e) => {
      setPaymentError(e.message);
      rollbar.error(`NEW_CHECKOUT_MUTATION onError: ${e.message}`, e);
    },
    onCompleted: ({ exoNewCheckout }) => {
      console.log('exoNewCheckouttttt', exoNewCheckout);
      rollbar.info('NEW_CHECKOUT_MUTATION onCompleted', exoNewCheckout);
      history.push(routes.orderCompleted, {
        isSuccess: true,
        order: exoNewCheckout,
      });
    },
    refetchQueries: [
      { query: ITEMS_QUERY, variables: { state: 'arrived_at_cairo_office' } },
    ],
    awaitRefetchQueries: true,
    // update: (cache, { data: { exoNewCheckout } }) => {
    //   const queryObj = {
    //     query: ITEMS_QUERY,
    //     variables: { state: 'arrived_at_cairo_office' },
    //   };

    //   const itemIds = exoNewCheckout.items.map((i) => i.id);

    //   const { exoItems = { nodes: [], total: itemIds.length } } =
    //     cache.readQuery(queryObj) || {};

    //   const filteredExoItems = {
    //     ...exoItems,
    //     nodes: exoItems.nodes.filter((item) => !itemIds.includes(item.id)),
    //     total: exoItems.total - itemIds.length,
    //   };

    //   cache.writeQuery({
    //     ...queryObj,
    //     data: { exoItems: filteredExoItems },
    //   });
    // },
  });

  const completeOrderHandler = async (getPaymentMethodData) => {
    let paymentDetails;

    if (getPaymentMethodData instanceof Function) {
      paymentDetails = await getPaymentMethodData();
      console.log('paymentDetails', paymentDetails);

      if (paymentDetails.error) {
        setPaymentError(paymentDetails.error.message);
        return;
      } else {
        setPaymentError(undefined);
      }
    }

    setNoAddressError(!selectedAddressId);
    if (!selectedAddressId) {
      console.log('NoAddressError');
      return;
    }

    // mutate
    console.log('mutate');

    const mtnVariables = {
      itemIds:
        order.id === '0' ? order.items.map((i) => parseInt(i.id)) : undefined,
      orderId: order.id !== '0' ? parseInt(order.id) : undefined,
      addressId: parseInt(selectedAddressId),
      paymentMethod: selectedPaymentMethod,
      paymentDetails,
    };

    rollbar.info('completeOrderHandler variables', mtnVariables);

    mtnFnc({ variables: mtnVariables });
  };

  const completeOrderBtn = (onClick) => (
    <>
      <List verticalAlign="middle">
        <List.Item>
          <List.Content floated="right">
            {selectedPaymentMethod === 'CreditCard' && (
              <Header>
                ${asCurrency(paymentAmountUsd)} ({asCurrency(paymentAmount)}{' '}
                EGP)
              </Header>
            )}
            {selectedPaymentMethod !== 'CreditCard' && (
              <Header>{asCurrency(paymentAmount)} EGP</Header>
            )}
          </List.Content>
          <List.Content>
            <Header>Total Due</Header>
          </List.Content>
        </List.Item>
      </List>

      <Button
        fluid
        size="large"
        icon
        labelPosition="right"
        positive
        onClick={onClick}
        disabled={loading || mtnLoading}
        loading={loading || mtnLoading}
      >
        <Icon name="chevron right" />
        Place Order
      </Button>
    </>
  );

  if (loading) {
    return <Loader active size="large" />;
  }

  return (
    <>
      <div style={{ margin: '3rem', marginBottom: '6rem' }}>
        <Link to={routes.dashboard}>
          <Image centered src={logoImg} />
        </Link>
      </div>

      <Grid padded="horizontally" centered stackable>
        <Grid.Column
          mobile={16}
          tablet={8}
          computer={8}
          largeScreen={7}
          widescreen={4}
        >
          <Header>Shipping Address</Header>
          <Segment>
            <AddressSelector
              selectedId={selectedAddressId}
              onSelect={(address) => {
                if (address?.id) {
                  setSelectedAddressId(address.id);
                  if (noAddressError) setNoAddressError(false);
                }
              }}
              autoOpenNew
            />
            {noAddressError && (
              <Message negative content="Please select shipping address." />
            )}
          </Segment>

          <Header>Payment Method</Header>
          {paymentMethods.map((method) => (
            <PaymentMethod
              key={method}
              method={method}
              exoCheckout={exoCheckout}
              isSelected={selectedPaymentMethod === method}
              onSelect={setSelectedPaymentMethod}
              error={paymentError}
              completeOrderBtn={completeOrderBtn}
              completeOrderHandler={completeOrderHandler}
            />
          ))}
        </Grid.Column>

        <Grid.Column
          mobile={16}
          tablet={8}
          computer={8}
          largeScreen={7}
          widescreen={4}
        >
          <Segment.Group>
            <Segment>
              <Header textAlign="center">Checkout Summary</Header>
              <Divider />
              <Item.Group>
                {order.items.map((item) => (
                  <Item key={item.id}>
                    <Item.Image rounded size="tiny" src={item.photo?.url} />

                    <Item.Content verticalAlign="middle">
                      <b>{item.id}</b>
                      <Header size="small" floated="right">
                        ${asCurrency(item.priceUsd)} ({asCurrency(item.price)}{' '}
                        EGP)
                      </Header>
                      <Item.Extra>
                        {item.weight}kg, {item.categoryName}
                      </Item.Extra>
                    </Item.Content>
                  </Item>
                ))}
              </Item.Group>
            </Segment>
            <Segment>
              <List verticalAlign="middle">
                <List.Item>
                  <List.Content floated="right">
                    <Header size="small">
                      {asCurrency(order.subtotal)} EGP
                    </Header>
                  </List.Content>
                  <List.Content>
                    <Header size="small">Subtotal</Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content floated="right">
                    <Header size="small">
                      {asCurrency(order.delivery)} EGP
                    </Header>
                  </List.Content>
                  <List.Content>
                    <Header size="small">Delivery</Header>
                  </List.Content>
                </List.Item>
                {order.adjustments !== 0 && (
                  <List.Item>
                    <List.Content floated="right">
                      <Header color="green" size="small">
                        {asCurrency(order.adjustments)} EGP
                      </Header>
                    </List.Content>
                    <List.Content>
                      <Header size="small">Discount</Header>
                    </List.Content>
                  </List.Item>
                )}
                {walletPaymentAmount > 0 && (
                  <List.Item>
                    <List.Content floated="right">
                      <Header color="green" size="small">
                        -{asCurrency(walletPaymentAmount)} EGP
                      </Header>
                    </List.Content>
                    <List.Content>
                      <Header size="small">
                        Wallet Credit Applied{' '}
                        <Popup
                          trigger={
                            <Icon
                              style={{ fontSize: '1.2rem' }}
                              name="info circle"
                            />
                          }
                          content={`Remaining wallet balance: ${asCurrency(
                            walletRestBalance
                          )} EGP`}
                          inverted
                        />
                      </Header>
                    </List.Content>
                  </List.Item>
                )}
                <List.Item>
                  <List.Content floated="right">
                    <Header>{asCurrency(paymentAmount)} EGP</Header>
                  </List.Content>
                  <List.Content>
                    <Header>Total</Header>
                  </List.Content>
                </List.Item>
              </List>
            </Segment>
          </Segment.Group>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default Comp;
