import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment,
} from 'semantic-ui-react';

import { useError } from 'hooks';
import { routes } from 'config';
import { FORGET_PASSWORD_MUTATION } from 'gql';

import logoImg from 'assets/images/logo-blue.svg';

const Comp = () => {
  const history = useHistory();
  const location = useLocation();

  const { redirectLocation } = location.state || {
    redirectLocation: { pathname: routes.home },
  };

  const [email, setEmail] = useState('');

  const [mtnFnc, { loading, error }] = useMutation(FORGET_PASSWORD_MUTATION, {
    onError: () => null,
    variables: { email },
    onCompleted: () => {
      history.replace(routes.resetPassword, { redirectLocation });
    },
  });

  const errorMsg = useError(error);

  return (
    <Grid centered style={{ height: '100vh' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <div style={{ textAlign: 'center' }}>
          <Link to="/">
            <Image verticalAlign="middle" centered src={logoImg} />
          </Link>
        </div>
        <Header as="h2" textAlign="center">
          Forgot your password?
        </Header>
        <Form
          size="large"
          loading={loading}
          error={!!errorMsg}
          onSubmit={mtnFnc}
        >
          <Segment stacked>
            <Form.Input
              required
              fluid
              icon="user"
              iconPosition="left"
              label="E-mail address"
              placeholder="E-mail address"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <Message error content={errorMsg} />

            <Button primary fluid size="large">
              Submit
            </Button>
          </Segment>
        </Form>
        <Message style={{ textAlign: 'center' }}>
          Remember your password?{' '}
          <Link to={{ pathname: routes.signIn, state: { redirectLocation } }}>
            Sign In
          </Link>
        </Message>
      </Grid.Column>
    </Grid>
  );
};

export default Comp;
