import { gql } from '@apollo/client';
import EXO_SEARCH_FRAGMENT from '../f/exoSearch';

export default gql`
  query exoSearch($term: String!) {
    exoSearch(term: $term) {
      ...ExoSearchFragment
    }
  }
  ${EXO_SEARCH_FRAGMENT}
`;
