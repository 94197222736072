import { format } from 'date-fns';

export { default as apolloClient } from './apolloClient';
export { default as reportWebVitals } from './reportWebVitals';
export { default as routes } from './routes';

export function humanize(str) {
  let i,
    frags = str.split('_');
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
}

export function asCurrency(number) {
  if (number === null) return '--';
  return number
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function dateFormate(dateStr, formatStr) {
  const a = dateStr.split(/[^0-9]/);
  const d = new Date(a[0], a[1] - 1, a[2], a[3], a[4], a[5]);
  return format(d, formatStr);
}
