import { gql } from '@apollo/client';
import USER_FRAGMENT from '../f/user';

export default gql`
  mutation login($email: String!, $password: String!) {
    login(auth: { email: $email, password: $password }) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;
