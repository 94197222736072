import React, { useState } from 'react';
import {
  Container,
  Grid,
  Form,
  Button,
  Segment,
  Header,
  Message,
  Icon,
} from 'semantic-ui-react';
import { Map, Marker, GoogleApiWrapper, InfoWindow } from 'google-maps-react';
import { MainLayout } from 'components';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const Comp = (props) => {
  const [activeMarker, setActiveMarker] = useState({});
  const [selectedPlace, setSelectedPlace] = useState({});
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(true);

  async function sendMessage(variables) {
    fetch('https://api.lynks.com/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
        mutation($email: String!, $name: String!, $message: String!) {
            contactUs(email: $email, name: $name, message: $message)
        } 
        `,
        variables: {
          name: variables.name,
          email: variables.email,
          message: variables.message,
        },
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        setLoading(false);
        formik.handleReset();
        setShowForm(false);
      })
      .catch((err) => console.log(err));
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
      message: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email').required('Required'),
    }),
    onSubmit: (variables) => {
      setLoading(true);
      console.log(variables);
      sendMessage(variables);
    },
  });

  function handleDismiss() {
    setShowForm(false);
    setTimeout(() => {
      setShowForm(true);
    }, 200);
  }

  const onMarkerClick = (props, marker) => {
    setActiveMarker(marker);
    setSelectedPlace(props);
    setShowingInfoWindow(true);
  };
  const onMapClicked = () => {
    if (showingInfoWindow) {
      setActiveMarker(null);
      setShowingInfoWindow(false);
    }
  };
  const onInfoWindowClose = () => {
    setShowingInfoWindow(false);
  };

  return (
    <MainLayout>
      <div
        style={{
          backgroundRepeat: 'no-repeat',
          paddingTop: '5rem',
          paddingBottom: '5rem',
        }}
      >
        <Container>
          <Grid>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <div style={{ height: '60vh', width: '100%' }}>
                <Map
                  onClick={onMapClicked}
                  google={props.google}
                  style={{ width: '100%', height: '100%' }}
                  initialCenter={{
                    lat: 31.208813,
                    lng: 29.934398,
                  }}
                  zoom={16}
                >
                  <Marker
                    title={'Lynks Office'}
                    name={
                      '1 Fawzy Moaz St. Sama Semouha Building, 10th Floor, Above Kabani Furnitures, Semouha, Alexandria'
                    }
                    position={{ lat: 31.208813, lng: 29.934398 }}
                    onClick={onMarkerClick}
                  />
                  <InfoWindow
                    marker={activeMarker}
                    onClose={onInfoWindowClose}
                    visible={showingInfoWindow}
                  >
                    <div>
                      <h4>{selectedPlace.name}</h4>
                    </div>
                  </InfoWindow>
                </Map>
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <Segment
                padded="very"
                style={{
                  marginLeft: '1rem',
                  marginTop: '1rem',
                  minHeight: '100%',
                }}
              >
                {showForm ? (
                  <Form
                    loading={loading}
                    size="large"
                    onSubmit={formik.handleSubmit}
                  >
                    <Header as="h3">Contact Us</Header>
                    <Header as="h5" color="grey">
                      1 Fawzy Moaz St. Sama Semouha Building, 10th Floor, Above
                      Kabani Furnitures, Semouha, Alexandria
                    </Header>
                    <Form.Input
                      required
                      id="email"
                      name="email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      label="Email"
                      control="input"
                      placeholder="example@example.com"
                      error={formik.touched.email && !!formik.errors.email}
                    />
                    <Form.Input
                      required
                      id="name"
                      name="name"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      label="Name"
                      control="input"
                      placeholder="Full Name"
                    />
                    <Form.TextArea
                      required
                      id="message"
                      name="message"
                      onChange={formik.handleChange}
                      value={formik.values.message}
                      label="Message"
                      placeholder="Message"
                    />
                    <Button
                      type="submit"
                      color="teal"
                      fluid
                      size="large"
                      disabled={
                        !(
                          formik.values.email &&
                          formik.values.name &&
                          formik.values.message
                        ) || loading
                      }
                    >
                      Send
                    </Button>
                  </Form>
                ) : (
                  <Message
                    positive
                    onDismiss={handleDismiss}
                    style={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '20px',
                      transition: 'all 0.2s ease-in-out',
                    }}
                  >
                    <Message.Header style={{ textAlign: 'center' }}>
                      <Icon name="check circle" size="huge" />
                      <br />
                      <h1> Thank you for contacting us. </h1>
                    </Message.Header>
                    <p
                      style={{
                        fontSize: '1.7rem',
                        padding: '0.5rem',
                        textAlign: 'center',
                      }}
                    >
                      Your message has been received and we will get back to you
                      as soon as possible.
                    </p>
                  </Message>
                )}
              </Segment>
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    </MainLayout>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBbHtrKYFxHtUYP6I7QeTTWB459F_Nqosk',
})(Comp);
