import React, { useState, Fragment } from 'react';
import { Header, Container, Segment, Icon, Accordion } from 'semantic-ui-react';

import { MainLayout } from 'components';

const FAQ = [
  // Section 1
  {
    title: 'General',
    list: [
      {
        q: 'What is Exohub?',
        a:
          'Exohub is an international shipping service for businesses that makes it easy to buy products from the USA, UK or UAE and ship them to any address in Egypt. Exohub handles international shipping, customs clearance and last mile delivery at very competitive rates.',
      },

      {
        q: 'How does it work?',
        a:
          'After you sign up at exohub.com, you get your own personalized shipping address in the USA, China, UK & UAE. You can then shop from any online store and ship to your personalized address. Once we receive your package at any of our warehouses, they’ll automatically get consolidated and shipped to Egypt and cleared from customs. You’ll be notified when the package arrives in Egypt to schedule the delivery.',
      },
      {
        q: 'How can I create an account on Exohub?',
        a:
          'Click on the “Create Free Account” button in the header, fill in the required details, then click the “Submit” button. ',
      },
      {
        q: 'Do I need to submit any verification documents to use Exohub?',
        a:
          'Yes. After you sign up, you will be required to submit your national ID or Passport to start using Exohub.',
      },
      {
        q: 'How can I contact you?',
        a:
          'Use our website live chat or message us on Facebook Messenger using this link: m.me/ExohubLogistics',
      },
      {
        q: 'What are your working hours?',
        a:
          'For customer support inquires, our working hours in Egypt are Saturday to Thursday, 10am to 6pm.',
      },
    ],
  },
  // Section 2
  {
    title: 'Shopping Global Stores',
    list: [
      {
        q: 'Where can I shop?',
        a:
          'You can shop at any online store that ships to USA, UK or UAE.',
      },
      {
        q: 'How do I shop?',
        a:
          'After you choose the store you want to shop from (ex. Amazon), you should add the products you want to buy to your store cart and checkout. In the checkout process, you should enter your Exohub shipping address in the shipping address area.',
      },
      {
        q: 'Will I pay sales tax on my USA orders?',
        a:
          'No, you will not pay any sales tax on your orders from US stores as the shipping address is in Delaware state, which does not charge any sales tax on online purchases.',
      },
      {
        q: 'What happens after I place the order on the store?',
        a:
          'After you place the order successfully, it will be shipped to Exohub warehouse and you will be notified once it arrives.',
      },
    ],
  },
  // Section 3
  {
    title: 'Package Receiving & Shipping',
    list: [
      {
        q: 'How do I know my shipments arrived at Exohub warehouse?',
        a:
          'You will be notified after your shipments are received in our warehouse and processed successfully. You will be able to see the number of items in each shipment and also a real photo of each individual item.',
      },
      {
        q: 'Will I pay any additional fees for storage or warehousing?',
        a: 'No, the price you pay already includes storage and warehousing.',
      },
      {
        q: 'What happens after my shipments are received and processed?',
        a:
          'Your shipments will then be consolidated and shipped internationally to Egypt.',
      },
      {
        q: 'How long does it take to deliver my shipments with Exohub?',
        a:
          'It takes 10 - 20 business days to deliver your shipments after they arrive at any of our warehouses.',
      },
      {
        q:
          'Do you guarantee that my items will arrive in its original condition?',
        a:
          'Yes, we guarantee that you will receive your items in the same condition received to our US warehouse from the original store. However, please note that the items might be unsealed due to customs inspection.',
      },
      {
        q:
          'What happens if my orders get lost or damaged during international shipping?',
        a:
          'Your orders are insured against being lost or damaged during shipping. You can submit a claim and submit the original invoices of the items and you will get a full refund of the item price.',
      },
      {
        q: 'What items can I ship with Exohub?',
        a:
          'Only the following categories are allowed to ship with Exohub: Fashion / Watches / Beauty & Make up / Laptops & Computers',
      },
      {
        q: 'What happens if I buy other items that are not allowed by Exohub?',
        a:
          'If a package that contains items that are prohibited was received at Exohub Warehouse. The package will be returned back to the seller. Any expenses related to the return process will be billed to you.',
      },
      {
        q: 'Can I track my shipments?',
        a:
          'Yes, you can track your shipments at every step using the tracking number of your shipments. Also, you can see photos of the contents of each shipment once they arrive at any of our warehouses.',
      },
      {
        q: 'Do you ship to countries other than Egypt?',
        a:
          'Exohub ships to Egypt only at the time being. However, please stay tuned to know whether we will ship to your country in the future.',
      },
    ],
  },
  // Section 4
  {
    title: 'Payment Information',
    list: [
      {
        q: 'How do you calculate the weight of my shipments?',
        a:
          'We weigh each product in your shipments once they arrive at our warehouse in Egypt, you will be charged for each 100 grams of weight. For example, if the item weight is 460 grams, the item weight will be approximated to 500 grams.',
      },
      {
        q: 'How do you calculate the total cost of my shipments?',
        a:
          'Once your orders arrive in any of our warehouses, it gets automatically shipped to Egypt and cleared from customs. After your products arrive in our Egypt office, each product gets weighed individually and the price is calculated based on the weight and the category of the product.',
      },
      {
        q: 'How much is the total cost?',
        a:
          'You can use our calculator to know the exact total cost based on the category and the weight of the product. There are no hidden fees or extra fees on delivery.',
      },
      {
        q: 'Will I pay customs duties on delivery?',
        a:
          'No, the price you get already includes customs charges and any other fees needed to clear your shipments.',
      },
      {
        q: 'What payment methods do you accept?',
        a: 'Cash on Delivery / Online credit or debit card / CIB bank deposit.',
      },
      {
        q: 'What currencies do you accept?',
        a: 'Your total cost can be paid in EGP (Egyptian Pounds) only.',
      },
      {
        q: 'What is Exohub Wallet?',
        a:
          'Exohub wallet is a virtual wallet that you can use to pay for your orders and get any refunds for your payments.',
      },
    ],
  },
];

const Comp = () => {
  const [activeIndex, setActiveIndex] = useState(`${FAQ[0].title}${0}`);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  return (
    <MainLayout>
      <div
        style={{
          backgroundRepeat: 'no-repeat',
          minHeight: '100vh',
          paddingTop: '5rem',
          paddingBottom: '5rem',
        }}
      >
        <Container text>
          <Segment padded>
            <Header size="large">FAQs</Header>
            {FAQ.map((section) => (
              <div key={section.title} style={{ marginTop: '2rem' }}>
                <Header size="small">{section.title}</Header>
                <Accordion fluid styled>
                  {section.list.map((obj, index) => {
                    const i = `${section.title}${index}`;

                    return (
                      <Fragment key={i}>
                        <Accordion.Title
                          active={i === activeIndex}
                          index={i}
                          onClick={handleClick}
                        >
                          <Icon name="dropdown" />
                          {obj.q}
                        </Accordion.Title>
                        <Accordion.Content active={i === activeIndex}>
                          {obj.a}
                        </Accordion.Content>
                      </Fragment>
                    );
                  })}
                </Accordion>
              </div>
            ))}
          </Segment>
        </Container>
      </div>
    </MainLayout>
  );
};

export default Comp;
