import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Card,
  Transition,
  Segment,
  Header,
  Button,
  Grid,
  Dropdown,
  Icon,
  Menu,
  Label,
} from 'semantic-ui-react';
import {
  DashboardLayout,
  ItemCard,
  SearchInput,
  CalculatorModal,
  ItemsCsvModal,
} from 'components';

import { routes } from 'config';

import { ITEMS_QUERY } from 'gql';

import Activities from './Activities';
import NoItems from './NoItems';
import { asCurrency } from 'config';

const options = [
  {
    key: 'Arrived at Cairo Warehouse',
    text: 'Arrived at Cairo Warehouse',
    value: 'arrived_at_cairo_office',
  },
  {
    key: 'In Transit to Egypt',
    text: 'In Transit to Egypt',
    value: 'in_transit_to_cairo',
  },
  {
    key: 'Arrived at US Warehouse',
    text: 'Arrived at US Warehouse',
    value: 'arrived_at_us_office',
  },
  {
    key: 'Arrived at UK Warehouse',
    text: 'Arrived at UK Warehouse',
    value: 'arrived_at_uk_office',
  },
  {
    key: 'Arrived at AE Warehouse',
    text: 'Arrived at AE Warehouse',
    value: 'arrived_at_ae_office',
  },
  {
    key: 'Arrived at CN Warehouse',
    text: 'Arrived at CN Warehouse',
    value: 'arrived_at_cn_office',
  },
];

const PER = 24;

const Comp = () => {
  const history = useHistory();

  const [selectedState, setSelectedState] = useState(options[0].value);

  const isSelectable = selectedState === 'arrived_at_cairo_office';

  const variables = {
    state: selectedState,
    per: selectedState === options[0].value ? 500 : PER,
  };

  const { data = {}, loading, fetchMore } = useQuery(ITEMS_QUERY, {
    variables,
  });
  const { exoItems = {} } = data;
  const { nodes = [], page, per, total } = exoItems;

  const onFetchMore = () =>
    fetchMore({
      variables: { state: selectedState, per: PER, page: page + 1 },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          ...fetchMoreResult,
          exoItems: {
            ...fetchMoreResult.exoItems,
            nodes: [...prev.exoItems.nodes, ...fetchMoreResult.exoItems.nodes],
          },
        };
      },
    });

  const isNextPage = total > per * page;

  const [selectedItems, setSelectedItems] = useState([]);

  const onSelect = (item) => setSelectedItems((list) => [...list, item]);
  const onUnselect = (item) =>
    setSelectedItems((list) => list.filter((e) => e.id !== item.id));

  const selectAll = () => setSelectedItems(nodes.filter((i) => !!i.price));
  const deselectAll = () => setSelectedItems([]);

  const subMenu = () => {
    return (
      <Grid reversed="computer" stackable verticalAlign="middle">
        <Grid.Column width={4}>
          <SearchInput />
        </Grid.Column>
        <Grid.Column width={6}>
          <Menu
            widths={4}
            style={{ marginTop: '-1rem', marginBottom: '-1rem' }}
          >
            <Activities
              trigger={
                <Menu.Item>
                  <b>Live Activities</b>
                </Menu.Item>
              }
            />
            <CalculatorModal
              trigger={
                <Menu.Item>
                  <b>Price Calculator</b>
                </Menu.Item>
              }
            />
            <Menu.Item link as={Link} to={routes.faq}>
              <b>FAQ</b>
            </Menu.Item>
            <ItemsCsvModal
              trigger={
                <Menu.Item>
                  <b>Export CSV</b>
                </Menu.Item>
              }
            />
          </Menu>
        </Grid.Column>
        <Grid.Column width={6}>
          <Icon name="filter" />
          <Dropdown
            inline
            options={options}
            defaultValue={selectedState}
            onChange={(e, { value }) => {
              setSelectedState(value);
            }}
          />
          <span style={{ margin: '0 1rem' }}>
            Showing ({nodes.length}/{total})
          </span>
        </Grid.Column>
      </Grid>
    );
  };

  const selectedItemsTotalPrice = selectedItems.reduce(
    (p, e) => p + e.price,
    0
  );

  const selectedItemsTotalWeight = selectedItems.reduce(
    (p, e) => p + e.weight,
    0
  );

  return (
    <DashboardLayout subMenu={subMenu()} loading={loading}>
      <Helmet>
        <meta charset="utf-8" />
        <title>Exohub: Dashboard</title>
      </Helmet>
      {isSelectable && !!nodes.length && (
        <Header style={{ paddingBottom: '1rem', paddingLeft: '1rem' }}>
          Select items to checkout{' '}
          <Button
            size="mini"
            style={{ marginLeft: '1rem' }}
            onClick={selectAll}
          >
            <b>Select All</b>
          </Button>
          {!!selectedItems.length && (
            <Label style={{ marginTop: '1px' }}>
              Selected ({selectedItems.length}/{nodes.length})
              <Icon onClick={deselectAll} color="red" name="close" />
            </Label>
          )}
        </Header>
      )}
      {!!nodes.length ? (
        <Card.Group stackable>
          {nodes.map((item) => (
            <ItemCard
              key={item.id}
              item={item}
              onSelect={isSelectable ? onSelect : undefined}
              onUnselect={isSelectable ? onUnselect : undefined}
              selected={!!selectedItems.find((e) => e.id === item.id)}
            />
          ))}
        </Card.Group>
      ) : (
        <NoItems />
      )}

      {isNextPage && (
        <Segment basic padded="very" textAlign="center">
          <Button size="large" onClick={onFetchMore}>
            Load more
          </Button>
        </Segment>
      )}

      <div style={{ height: '10rem' }} />
      <Transition.Group animation="fade up" duration={500}>
        {!!selectedItems.length && (
          <div
            style={{
              position: 'fixed',
              left: 0,
              right: 0,
              bottom: 0,
              // padding: '2rem',
              // backgroundColor: '#9BA3BB',
              zIndex: 99,
            }}
          >
            <Segment
              padded
              raised
              color="blue"
              size="large"
              style={{ borderRadius: 0 }}
            >
              <Grid columns="equal" stackable verticalAlign="middle">
                <Grid.Column>
                  <Header size="medium">
                    Total Price: {asCurrency(selectedItemsTotalPrice)} EGP
                    <Header.Subheader>
                      Total Chargeable Weight:{' '}
                      {asCurrency(selectedItemsTotalWeight)} KG
                    </Header.Subheader>
                  </Header>
                </Grid.Column>
                <Grid.Column>
                  <Button
                    size="huge"
                    color="green"
                    floated="right"
                    onClick={() =>
                      history.push(routes.checkout, {
                        itemIds: selectedItems.map((e) => parseInt(e.id)),
                      })
                    }
                  >
                    Checkout ({selectedItems.length} Items)
                  </Button>
                </Grid.Column>
              </Grid>
            </Segment>
          </div>
        )}
      </Transition.Group>
    </DashboardLayout>
  );
};

export default Comp;
