import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import fetch from 'cross-fetch';
import { RetryLink } from '@apollo/link-retry';
import { setContext } from '@apollo/link-context';
import { onError } from '@apollo/client/link/error';

let uri;

// uri = 'http://localhost:3000/graphql';
uri = 'http://localhost:3030/exo-graphql';
// uri = 'https://api.exohub.com/exo-graphql';
// uri = 'https://staging-api.lynks.com/graphql';

if (process.env.NODE_ENV === 'production') {
  uri = 'https://api.lynks.com/exo-graphql';
}

if (process.env.REACT_APP_IS_SANDBOX === 'true') {
  uri = 'https://sandbox-api.exohub.com/exo-graphql';
}

const httpLink = new HttpLink({ uri, fetch });

// retry link
const retryLink = new RetryLink({
  delay: () => 2500,
  attempts: (count, operation, error) => {
    console.log('retryLink error', error);
    if (
      process.env.NODE_ENV !== 'production' ||
      !error ||
      [401, 500].includes(error.statusCode)
    ) {
      return false;
    }

    return true;
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const link = ApolloLink.from([retryLink, errorLink, httpLink]);

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : undefined,
      'Api-Context': 'exohub',
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          // exoItems: {
          //   keyArgs: ['state'],
          //   merge(existing = { nodes: [] }, incoming) {
          //     return {
          //       ...incoming,
          //       nodes: [...existing.nodes, ...incoming.nodes],
          //     };
          //   },
          // },
          exoEvents: {
            keyArgs: false,
            merge(existing = { nodes: [] }, incoming) {
              return {
                ...incoming,
                nodes: [...existing.nodes, ...incoming.nodes],
              };
            },
          },
          exoOrders: {
            keyArgs: false,
            merge(existing = { nodes: [] }, incoming) {
              return {
                ...incoming,
                nodes: [...existing.nodes, ...incoming.nodes],
              };
            },
          },
        },
      },
    },
  }),
  link: authLink.concat(link),
  // link: authLink.concat(httpLink),
  resolvers: {},
  // defaultOptions: {
  //   watchQuery: {
  //     errorPolicy: 'all',
  //   },
  //   query: {
  //     errorPolicy: 'all',
  //   },
  //   mutate: {
  //     errorPolicy: 'all',
  //   },
  // },
});

export default client;
