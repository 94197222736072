import React from 'react';
import {
  Header,
  Container,
  Segment,
  List,
  Grid,
  Divider,
} from 'semantic-ui-react';

import { MainLayout } from 'components';

const Terms = [
  // Section 1
  {
    title: 'Account & Registration Terms',
    list: [
      'You must provide your legal full name, a valid email address, and any other information requested in order to complete the signup process.',
      'One person or legal entity may not maintain more than one account.',
      'You are responsible for maintaining the security of your account and password. You are responsible for maintaining the confidentiality of your account and password. The Service disclaims any responsibility or liability to any loss or damage of any kind resulting from your failure to comply with the Service security obligations.',
      'You may not use the Service for any illegal or unauthorized purpose.',
      'You must provide a valid National ID or Passport copy in order to user Exohub. The documents submitted must belong to the owner of the Exohub account and must match the legal name provided.',
      'If we have reason to believe that any information provided is untrue or incomplete, we reserve the right to close your account or limit your account access until such discrepancy is corrected.',
      'We never use data, including email addresses, for any purpose other than to provide you with the Service as well as to send you opt-in email alerts and occasional informational emails relating to the Service.',
    ],
  },
  // Section 2
  {
    title: 'Pricing & Payments',
    list: [
      'You must make payments using only payment methods owned by you. You may not make payments using accounts owned by others. Exohub reserves the right to close your account or limit your account access until such discrepancy is corrected.',
      'Exohub reserves the right to change prices for any service provided without advance notice.',
      'International Shipping costs are based on the weight of your items, the items category and the shipping speed.',
      'The all-in pricing including customs & clearance costs is for products priced $100 or less for Clothing, Shoes, Suppplements & Beauty products. For Electronics, the all-in pricing is up to $1000. Products that exceed these values must be declared and will not be subject to the all-in pricing provided by Exohub.',
    ],
  },
  // Section 3
  {
    title: 'Product Quality',
    list: [
      "Exohub is not responsible for any defects in the products you purchase unless those defects were a direct result of international shipping or handling. Exohub doesn't guarantee the quality or suitability of the products you purchase.",
      'Exohub guarantees to deliver your items in the same condition they were received at any of our global warehouses.',
    ],
  },
  // Section 4
  {
    title: 'Package Receiving & Handling',
    list: [
      'Packages received at any of our warehouses will be opened, inspected and added to your Exohub dashboard.',
      "We reserve the right to reject any products that are classified as dangerous goods or prohibited from import by the destination country's customs laws & regulations.",
      'You agree to have your items consolidated with other items from the same category and shipped via any of our shipping partners.',
      'We are not responsible for packages that are not received and confirmed by Exohub staff at any of our warehouses.',
    ],
  },
  // Section 5
  {
    title: 'International Shipping',
    list: [
      'Products received at any of our warehouses will be automatically shipped to the destination country without prior notice or approval from you. You agree to have your items shipped automatically regardless of the weight, size or contents of the shipments.',
      'Transit time is defined by the shipping speed as indicated on the package. In some cases, your shipment might be delayed and you are not eligible for a refund unless your shipment has been delayed for 15 business days or more than the expected transit time defined by Exohub.',
      'In case a product is delayed for more than 15 business days, you are eligible for a refund as indicated in the "Returns & Refunds" section',
    ],
  },
  // Section 6
  {
    title: 'Delivery',
    list: [
      "Once your order is ready for delivery, you must checkout and pay the fees for those items within 90 days of the item arrival date to the destination country's warehouse. After that, your items will be abandoned and might be disposed of and you will not be eligible for any type of refunds.",
      'In case of receiving a physically damaged product(s), the incident should be reported within 2 business days starting from the delivery date to be able to take the proper action. Reporting past this period might not be considered.',
    ],
  },
  // Section 7
  {
    title: 'Returns & Refunds',
    list: [
      'In case the product was delayed for more than 15 business days, or if the product was damaged or lost during shipping, you are eligible for a refund.',
      'The maximum refund amount is $100 per product for Clothing, Shoes, Suppplements & Beauty products. For Electronics, the maximum refund amount is $1000.',
      "You must provide the original invoices from the store along with any other documents required by Exohub's staff to get the refund.",
      'Refunds will be issued to your Exohub digital wallet where you can use the credit to pay for Exohub services only.',
      "In case you wanted to return a package back to the store, you agree to pay the return fees in addition to Exohub service fees ($10 per package). Exohub doesn't guarantee that you get a refund from the seller after the package is successfully returned.",
    ],
  },
  // Section 8
  {
    title: 'Discounts & Promotions',
    list: [
      'You can apply a promotional offer to get a discount off the total price of your order. You can use a maximum of one promotional offer per order. If you use multiple promotional offers, only the offer with the maximum discount will apply to your order.',
      'Exohub reserves the right to modify or cancel any offers or coupons at any time without notifying users; Exohub also has the right to cancel any purchasing order in case of abuse or misuse of the promotional codes.',
    ],
  },
  // Section 9
  {
    title: 'Protection of Certain Personally-Identifying Information',
    list: [
      'Exohub discloses potentially personally-identifying and personally-identifying information only to those of its employees, contractors and affiliated organizations that (i) need to know that information in order to process it on Exohub behalf or to provide services available at Exohub websites, and (ii) that have agreed not to disclose it to others. Some of those employees, contractors and affiliated organizations may be located outside of your home country; by using Exohub websites, you consent to the transfer of such information to them. Exohub will not rent or sell potentially personally-identifying and personally-identifying information to anyone. Other than to its employees, contractors and affiliated organizations, as described above, Exohub discloses potentially personally-identifying and personally-identifying information only in response to a subpoena, court order or other governmental request, or when Exohub believes in good faith that disclosure is reasonably necessary to protect the property or rights of Exohub, third parties or the public at large. If you are a registered user of Exohub website and have supplied your email address, Exohub may occasionally send you an email to tell you about new features, solicit your feedback, or just keep you up to date with what’s going on with Exohub and our products. We primarily use our various product blogs to communicate this type of information, so we expect to keep this type of email to a minimum. If you send us a request (for example via a support email or via one of our feedback mechanisms), we reserve the right to publish it in order to help us clarify or respond to your request or to help us support other users. Exohub takes all measures reasonably necessary to protect against unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying information.',
    ],
  },
];

const Comp = () => {
  // const [activeIndex, setActiveIndex] = useState(`${Terms[0].title}${0}`);

  // const handleClick = (e, titleProps) => {
  //   const { index } = titleProps;
  //   const newIndex = activeIndex === index ? -1 : index;
  //   setActiveIndex(newIndex);
  // };

  return (
    <MainLayout>
      <div
        style={{
          backgroundRepeat: 'no-repeat',
          minHeight: '100vh',
          paddingTop: '5rem',
          paddingBottom: '5rem',
        }}
      >
        <Container text>
          <Segment padded>
            <Grid>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <Header size="large">Terms & Conditions</Header>
                <Divider />
                <List relaxed size="medium">
                  <List.Item>
                    <List.Content>
                      <List.Description>
                        By using exohub.com website, you agree to be bound by
                        the following terms and conditions of use. Exohub
                        reserves the right to change the Terms of Services from
                        time to time without notice. Any new features that
                        augment or enhance the current Service, including the
                        release of new tools and resources, will be effective
                        immediately upon being posted to the website.We will
                        notify you of any material changes to this Privacy
                        Policy by conspicuously posting the changes on the
                        website. Continued use of the Service after any such
                        changes shall constitute your consent to such changes.
                        You can review the most current version of the Terms of
                        Service on this page at any time. Violation of any of
                        the terms below will result in the termination of your
                        account.
                      </List.Description>
                    </List.Content>
                  </List.Item>
                </List>

                {Terms.map((section) => (
                  <div>
                    <Header attached="top">{section['title']}</Header>
                    <Segment attached="bottom">
                      <List bulleted>
                        {section['list'].map((section_item) => (
                          <List.Item>{section_item}</List.Item>
                        ))}
                      </List>
                    </Segment>
                    <Divider hidden />
                  </div>
                ))}
              </Grid.Column>
            </Grid>
          </Segment>
        </Container>
      </div>
    </MainLayout>
  );
};

export default Comp;
