import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Input, Modal, Card, Icon, Divider, Image } from 'semantic-ui-react';

import { SEARCH_QUERY } from 'gql';

import ItemCard from 'components/ItemCard';

const Comp = () => {
  const [term, setTerm] = useState('');
  const [result, setResult] = useState();

  const [searchQry, { loading }] = useLazyQuery(SEARCH_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: ({ exoSearch }) => {
      setResult(exoSearch);
    },
  });

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !!term) {
      searchQry({ variables: { term } });
    }
  };

  const notFound = (
    <Modal.Content>
      <Modal.Description style={{ textAlign: 'center' }}>
        <Image
          fluid
          src="https://altyseer.com/wp-content/plugins/elementor/assets/images/no-search-results.svg"
        />
        <br />
        <b>No results found.</b>
      </Modal.Description>
    </Modal.Content>
  );

  const item = result?.item && (
    <Modal.Content scrolling>
      <Modal.Description>
        <Card.Group itemsPerRow={1} stackable>
          <ItemCard item={result.item} withState />
        </Card.Group>
      </Modal.Description>
    </Modal.Content>
  );

  const renderPackage = result?.package && (
    <Modal.Content scrolling>
      <Modal.Description>
        <p>
          <Icon name="box" size="big" color="orange" />
        </p>
        <b>Package Tracking Number: {result.package.tracking}</b>
        <br />
        <b>Arrival Date: {result.package.arrivedAt}</b>
        <br />
        <b>Items Count: {result.package.items.length}</b>
        <Divider />

        <Card.Group itemsPerRow={3} stackable>
          {result.package.items.map((item) => (
            <ItemCard key={item.id} item={item} withState />
          ))}
        </Card.Group>
      </Modal.Description>
    </Modal.Content>
  );

  const modalContent = { notFound, item, package: renderPackage }[
    result?.resultType
  ];

  return (
    <>
      <Input
        fluid
        icon="search"
        size="large"
        placeholder="Search Item ID/Tracking Number"
        onChange={(e) => {
          setTerm(e.target.value);
        }}
        onKeyDown={handleKeyDown}
        loading={loading}
        value={term}
      />
      <Modal
        closeIcon
        open={!!term && result?.id === term}
        onClose={() => {
          setTerm('');
          setResult(undefined);
        }}
        size={result?.resultType !== 'package' ? 'mini' : undefined}
      >
        <Modal.Header>Search Result for {term}</Modal.Header>
        {modalContent}
      </Modal>
    </>
  );
};

export default Comp;
