import { gql } from '@apollo/client';
import USER_FRAGMENT from '../f/user';

export default gql`
  mutation register(
    $email: String!
    $password: String!
    $fullName: String!
    $phone: String!
  ) {
    register(
      sign_up_data: {
        auth: { email: $email, password: $password }
        user_info: {
          full_name: $fullName
          phone_number_attributes: { number: $phone }
        }
      }
    ) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;
