import { gql } from '@apollo/client';

export default gql`
  query exoCalc(
    $customsCategoryId: ID!
    $kgram: Int!
    $gram: Int!
    $shippingType: String
    $sourceCountry: String
  ) {
    exoCalc(
      customsCategoryId: $customsCategoryId
      kgram: $kgram
      gram: $gram
      shippingType: $shippingType
      sourceCountry: $sourceCountry
    ) {
      cost
      shipping
      customs
      clearance
      usdRate
      minsla
      maxsla
    }
  }
`;
