import { gql } from '@apollo/client';
import USER_FRAGMENT from '../f/user';

export default gql`
  mutation exoResetPassword($otp: String!, $password: String!) {
    exoResetPassword(otp: $otp, password: $password) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;
