import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import {
  List,
  Modal,
  Icon,
  Form,
  Header,
  Segment,
  Grid,
  Popup,
  Divider,
} from 'semantic-ui-react';

import { EXO_CUSTOMS_CATEGORIES_QUERY, CALC_QUERY } from 'gql';
import { asCurrency } from 'config';

const kgrams = [...new Array(26)].map((e, i) => ({
  key: `${i}`,
  text: `${i}`,
  value: i,
}));

const grams = [...new Array(10)].map((e, i) => {
  const value = i * 100;
  return {
    key: `${value}`,
    text: `${value}`,
    value,
  };
});

const countries = [
  {
    key: 'US',
    text: 'US',
    value: 'US',
    flag: 'us',
  },
  {
    key: 'UK',
    text: 'UK',
    value: 'UK',
    flag: 'uk',
  },
  {
    key: 'AE',
    text: 'AE',
    value: 'AE',
    flag: 'ae',
  },
  // {
  //   key: 'CN',
  //   text: 'CN',
  //   value: 'CN',
  //   flag: 'cn',
  // },
];

const types = [
  {
    key: 'Standard',
    text: 'Standard',
    value: 'Standard',
  },
  {
    key: 'Economy',
    text: 'Economy',
    value: 'Economy',
  },
  // {
  //   key: 'Express',
  //   text: 'Express',
  //   value: 'Express',
  // },
];

const Comp = (props) => {
  const [open, setOpen] = useState(false);
  const [customsCategoryId, setCategoryId] = useState();
  const [kgram, setKgram] = useState(kgrams[0].value);
  const [gram, setGram] = useState(grams[3].value);
  const [sourceCountry, setSourceCountry] = useState(countries[0].value);
  const [shippingType, setShippingType] = useState(types[0].value);

  const { data = {}, loading } = useQuery(EXO_CUSTOMS_CATEGORIES_QUERY);
  const { exoCustomsCategories = [] } = data;
  const customsCategories = exoCustomsCategories.map((e) => ({
    key: e.id,
    text: e.name,
    value: e.id,
  }));

  useEffect(() => {
    if (!customsCategoryId && !!customsCategories.length) {
      setCategoryId(customsCategories[0].value);
    }
  }, [customsCategoryId, customsCategories]);

  const { data: calcData = {} } = useQuery(CALC_QUERY, {
    variables: { customsCategoryId, kgram, gram, sourceCountry, shippingType },
    skip: [customsCategoryId, kgram, gram].includes(undefined),
  });

  const { exoCalc = {} } = calcData;
  const {
    cost = 0.0,
    shipping = 0.0,
    customs = 0.0,
    clearance = 0.0,
    usdRate = 0.0,
    minsla = 0,
    maxsla = 0,
  } = exoCalc;

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
      closeIcon
      {...props}
    >
      <Modal.Header>
        <Icon name="calculator" />
        Item Price Calculator
      </Modal.Header>
      <Modal.Content>
        <Grid stackable centered>
          <Grid.Column width={8}>
            <Form>
              <Form.Group widths="equal">
                <Form.Select
                  fluid
                  label="Source Country"
                  options={countries}
                  value={sourceCountry}
                  onChange={(e, { value }) => setSourceCountry(value)}
                />
                <Form.Select
                  fluid
                  label="Shipping Type"
                  options={types}
                  value={shippingType}
                  onChange={(e, { value }) => setShippingType(value)}
                />
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Select
                  fluid
                  label="Customs Category"
                  options={customsCategories}
                  value={customsCategoryId}
                  onChange={(e, { value }) => setCategoryId(value)}
                  loading={loading}
                />
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Select
                  fluid
                  label="Weight (Kilogram)"
                  options={kgrams}
                  value={kgram}
                  onChange={(e, { value }) => setKgram(value)}
                />
                <Form.Select
                  fluid
                  label="Weight (Gram)"
                  options={grams}
                  value={gram}
                  onChange={(e, { value }) => setGram(value)}
                />
              </Form.Group>
            </Form>
          </Grid.Column>

          <Grid.Column width={8}>
            <Segment>
              <b>Cost Breakdown</b>
              <List>
                <List.Item>
                  <List.Content floated="right">
                    {minsla} - {maxsla} Business Days
                  </List.Content>
                  <List.Content>Shipping Time</List.Content>
                </List.Item>

                <List.Item>
                  <List.Content floated="right">
                    {asCurrency(shipping)} USD
                  </List.Content>
                  <List.Content>International Shipping</List.Content>
                </List.Item>

                {customs > 0 && (
                  <>
                    <List.Item>
                      <List.Content floated="right">
                        {asCurrency(customs)} USD
                      </List.Content>
                      <List.Content>
                        Customs{' '}
                        <Popup
                          trigger={<Icon link name="info circle" />}
                          content="Customs costs are based on the average accepted customs valuations"
                          position="top center"
                        />
                      </List.Content>
                    </List.Item>
                  </>
                )}

                {clearance > 0 && (
                  <>
                    <List.Item>
                      <List.Content floated="right">
                        {asCurrency(clearance)} USD
                      </List.Content>
                      <List.Content>
                        Clearance Fees{' '}
                        <Popup
                          trigger={<Icon link name="info circle" />}
                          content="Fixed clearance fees that applies on each item"
                          position="top center"
                        />
                      </List.Content>
                    </List.Item>
                  </>
                )}

                <List.Item>
                  <List.Content floated="right">
                    {Math.round((customs + shipping + clearance) * 100) / 100} USD
                  </List.Content>
                  <List.Content>
                    Subtotal{' '}
                  </List.Content>
                </List.Item>

                {usdRate > 0 && (
                  <>
                    <List.Item>
                      <List.Content floated="right">
                        {usdRate}
                      </List.Content>
                      <List.Content>
                        USD Exchange Rate{' '}
                        <Popup
                          trigger={<Icon link name="info circle" />}
                          content="Exchange Rate is based on the official rate of the Central Bank of Egypt in addition to 3.5% conversion fees"
                          position="top center"
                        />
                      </List.Content>
                    </List.Item>
                  </>
                )}

                <Divider />

                <List.Item>
                  <List.Content floated="right">
                    <Header size="small" style={{ color: '#B12704' }}>
                      {asCurrency(cost)} EGP
                    </Header>
                  </List.Content>
                  <List.Content>
                    <b>
                      Total Cost{' '}
                      <Popup
                        trigger={<Icon link name="info circle" />}
                        content="Guaranteed cost per Item. No additional fees on delivery."
                        position="top center"
                      />
                    </b>
                  </List.Content>
                </List.Item>
              </List>
            </Segment>
          </Grid.Column>
        </Grid>
      </Modal.Content>
    </Modal>
  );
};

export default Comp;
