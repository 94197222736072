import React from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Card, Header, Icon, Container } from 'semantic-ui-react';

import { DashboardLayout, OrderCard, ItemCard } from 'components';
import { routes } from 'config';
import { ORDER_QUERY } from 'gql';

const Comp = () => {
  const history = useHistory();

  const { state = {} } = useLocation();
  const { isSuccess = false, order: initOrder = {} } = state;

  const { id = initOrder.id } = useParams();

  if (!id) {
    history.replace(routes.home);
  }

  const { data = {}, loading } = useQuery(ORDER_QUERY, {
    variables: { id },
    skip: !id,
  });
  const { exoOrder = { ...initOrder } } = data;

  return (
    <DashboardLayout
      subMenu={<Header>Order #{exoOrder?.id}</Header>}
      loading={loading}
    >
      <Container text>
        {isSuccess && (
          <div style={{ textAlign: 'center' }}>
            <Icon size="huge" color="green" name="check circle outline" />
            <Header>Order #{exoOrder?.id} has been placed successfully</Header>
          </div>
        )}

        {exoOrder?.id && <OrderCard full order={exoOrder} />}

        <Card.Group itemsPerRow={3} stackable>
          {exoOrder?.items?.map((item) => (
            <ItemCard key={item.id} item={item} simple />
          ))}
        </Card.Group>
      </Container>
    </DashboardLayout>
  );
};

export default Comp;
