import React, { useState } from 'react';
import { getUnixTime } from 'date-fns';
import { useMutation } from '@apollo/client';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Modal, Icon, Form, Button, Message } from 'semantic-ui-react';

import { ITEMS_CSV_MUTATION } from 'gql';
import { useAuth } from 'providers';

const Comp = (props) => {
  const { user } = useAuth();

  const today = new Date();
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState([
    new Date(today.getFullYear(), today.getMonth(), 1),
    today,
  ]);

  const [mtnFnc, { loading, data = {} }] = useMutation(ITEMS_CSV_MUTATION, {
    onError: () => null,
    variables: {
      from: getUnixTime(dateRange[0]),
      to: getUnixTime(dateRange[1]),
    },
  });
  const { exoItemsCsv } = data;

  const maskedMail = user.email.replace(
    /^(.)(.*)(.@.*)$/,
    (_, a, b, c) => a + b.replace(/./g, '*') + c
  );

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="tiny"
      closeIcon
      {...props}
    >
      <Modal.Header>
        <Icon name="arrow alternate circle down outline" />
        Export Items CSV
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group widths="equal">
            <SemanticDatepicker
              label="From Date - To Date"
              onChange={(e, data) => setDateRange(data.value)}
              value={dateRange}
              clearOnSameDateClick={false}
              clearable={false}
              datePickerOnly
              format="DD MMMM YYYY"
              type="range"
              filterDate={(date) => date <= today}
              showToday={false}
            />
          </Form.Group>
        </Form>
        {exoItemsCsv === true && (
          <Message positive>
            <Message.Header>CSV export in progress</Message.Header>
            <p>
              You will get an email at <b>{maskedMail}</b> with the required
              report.
            </p>
          </Message>
        )}
        {exoItemsCsv === false && (
          <Message negative>
            <Message.Header>CSV export failed</Message.Header>
            <p>Please try agin later.</p>
          </Message>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setOpen(false)}>
          Close
        </Button>
        <Button
          content="Submit"
          labelPosition="right"
          icon="send"
          onClick={() => mtnFnc()}
          positive
          loading={loading}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default Comp;
