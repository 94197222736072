import { gql } from '@apollo/client';
import EXO_EVENT_FRAGMENT from '../f/exoEvent';

export default gql`
  query exoEvents($per: Int, $page: Int) {
    exoEvents(per: $per, page: $page) {
      nodes {
        ...ExoEventFragment
      }
      page
      per
      total
    }
  }
  ${EXO_EVENT_FRAGMENT}
`;
