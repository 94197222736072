import React from 'react';
import { Segment } from 'semantic-ui-react';

import LayoutHeader from './LayoutHeader';

const Comp = ({ children, subMenu, loading }) => {
  return (
    <div style={{ height: '100vh' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '6px 12px',
          backgroundColor: '#3269ff',
          color: '#fff',
        }}
      >
        Please note that your Exohub pricing has been updated to reflect the
        recent increase in the USD exchange rates
      </div>
      <div
        style={{
          backgroundColor: '#F3F6FB',
          padding: '1rem 0.4rem 0',
        }}
      >
        <LayoutHeader subMenu={subMenu} />
      </div>

      <Segment
        style={{
          minHeight: '100%',
          borderRadius: 0,
          margin: '0 0.4rem 1rem',
          border: 'none',
          boxShadow: 'none',
          paddingTop: '3rem',
        }}
        loading={loading}
      >
        {children}
      </Segment>
    </div>
  );
};

export default Comp;
