import { gql } from '@apollo/client';
import EXO_ORDER_FRAGMENT from './exoOrder';

export default gql`
  fragment ExoCheckoutFragment on ExoCheckout {
    order {
      ...ExoOrderFragment
    }
    walletBalance
    walletPaymentAmount
    walletRestBalance
    paymentAmount
    paymentAmountUsd
    paymentMethods
  }
  ${EXO_ORDER_FRAGMENT}
`;
