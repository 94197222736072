import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useApolloClient, useQuery } from '@apollo/client';
import { useRollbar } from '@rollbar/react';

import { routes } from 'config';
import { USER_QUERY, WALLET_QUERY } from 'gql';

const AuthContext = React.createContext(null);

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const history = useHistory();
  const client = useApolloClient();
  const rollbar = useRollbar();

  const [token, setToken] = useState(localStorage.getItem('token'));

  const signIn = (user = {}, callback = () => null) => {
    if (!user.token) return;

    rollbar.configure({ payload: { person: user } });

    localStorage.setItem('token', user.token);
    client.writeQuery({
      query: USER_QUERY,
      data: { user },
    });
    setToken(user.token);
    callback();
  };

  const signOut = () => {
    rollbar.configure({ payload: { person: null } });

    setToken(null);
    setTimeout(async () => {
      await client.resetStore();
      localStorage.removeItem('token');
      history.replace(routes.home);
    }, 100);
    // callback();
  };

  const { data = {}, loading: userLoading } = useQuery(USER_QUERY, {
    onError: (e) => {
      if (e.message === 'Unauthorized') {
        signOut();
      }
    },
    skip: !token,
    // fetchPolicy: 'cache-only',
  });
  const { user = {} } = data;

  const { data: walletData = {}, loading: walletLoading } = useQuery(
    WALLET_QUERY,
    {
      skip: !token,
    }
  );
  const { exoWallet = 0.0 } = walletData;

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        user,
        userLoading,
        isToken: !!token,
        isUser: !!user.id,
        walletLoading,
        wallet: exoWallet,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
