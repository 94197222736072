import { gql } from '@apollo/client';

export default gql`
  fragment UserFragment on ExoUser {
    id
    full_name
    email
    token
    exohubInventoryId
    exohubAccountState
    exohubInventories {
      id
      shippingType
      line1
      line2
      countryIsoCode
      countryName
      cityName
      areaName
      stateName
      zipCode
      phoneNumber
    }
  }
`;
