import { gql } from '@apollo/client';
import ADDRESS_FRAGMENT from '../f/address';

export default gql`
  mutation createAddress(
    $cityId: ID
    $fullName: String
    $streetName: String
    $apartmentFloor: String
    $phoneNumber: String
  ) {
    createAddress(
      full_name: $fullName
      phone_number: $phoneNumber
      street_number: $streetName
      apartment_floor: $apartmentFloor
      city_id: $cityId
    ) {
      ...AddressFragment
    }
  }
  ${ADDRESS_FRAGMENT}
`;
