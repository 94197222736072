import { gql } from '@apollo/client';
import EXO_ITEM_FRAGMENT from './exoItem';
import EXO_PACKAGE_FRAGMENT from './exoPackage';

export default gql`
  fragment ExoSearchFragment on ExoSearch {
    id
    resultType
    item {
      ...ExoItemFragment
      events {
        id
        date
        eventType
        message
      }
    }
    package {
      ...ExoPackageFragment
    }
  }
  ${EXO_ITEM_FRAGMENT}
  ${EXO_PACKAGE_FRAGMENT}
`;
