import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment,
} from 'semantic-ui-react';

import { useError } from 'hooks';
import { useAuth } from 'providers';
import { routes } from 'config';
import { REGISTER_MUTATION } from 'gql';

import logoImg from 'assets/images/logo-blue.svg';

const Comp = () => {
  const { signIn } = useAuth();

  const history = useHistory();
  const location = useLocation();

  const { redirectLocation } = location.state || {
    redirectLocation: { pathname: routes.signedInHome },
  };

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [fullName, setFullName] = useState('');
  const [phone, setPhone] = useState('');

  const isMatchError =
    !!password && !!passwordConfirmation && password !== passwordConfirmation;

  const [registerMtn, { loading, error }] = useMutation(REGISTER_MUTATION, {
    onError: () => null,
    variables: { email, password, fullName, phone },
    onCompleted: ({ register }) => {
      window.fbq('track', 'CompleteRegistration');
      signIn(register, () => history.replace(redirectLocation));
    },
  });

  const errorMsg = useError(error);

  return (
    <Grid centered style={{ height: '100vh' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <div style={{ textAlign: 'center' }}>
          <Link to="/">
            <Image verticalAlign="middle" centered src={logoImg} />
          </Link>
        </div>
        <Header as="h2" textAlign="center">
          Create new account
        </Header>
        <Form
          size="large"
          loading={loading}
          error={!!errorMsg}
          onSubmit={registerMtn}
        >
          <Segment stacked>
            <Form.Input
              required
              fluid
              icon="user"
              iconPosition="left"
              label="E-mail address"
              // placeholder="E-mail address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              autoComplete="email"
              name="email"
              id="email"
            />
            <Form.Input
              required
              fluid
              icon="user"
              iconPosition="left"
              label="Full name"
              // placeholder="Full name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              autoComplete="name"
              name="name"
              id="name"
            />
            <Form.Input
              required
              fluid
              icon="user"
              iconPosition="left"
              label="Phone"
              // placeholder="Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              type="tel"
              autoComplete="tel"
              name="phone"
              id="phone"
            />
            <Form.Input
              required
              fluid
              icon="lock"
              iconPosition="left"
              label="Password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              autoComplete="new-password"
              name="password"
              id="password"
            />
            <Form.Input
              required
              fluid
              icon="lock"
              iconPosition="left"
              label="Re-enter Password"
              placeholder="Re-enter Password"
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              error={isMatchError}
              type="password"
              autoComplete="new-password"
              name="password-confirmation"
              id="password-confirmation"
            />

            <Message error content={errorMsg} />

            <Button primary fluid size="large" disabled={isMatchError}>
              Sign Up
            </Button>
          </Segment>
        </Form>
        <Message style={{ textAlign: 'center' }}>
          Already have an account?{' '}
          <Link to={{ pathname: routes.signIn, state: { redirectLocation } }}>
            Sign In Here.
          </Link>
        </Message>
      </Grid.Column>
    </Grid>
  );
};

export default Comp;
