import { gql } from '@apollo/client';
import ADDRESS_FRAGMENT from '../f/address';

export default gql`
  query addresses {
    addresses {
      ...AddressFragment
    }
  }
  ${ADDRESS_FRAGMENT}
`;
