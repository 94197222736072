import React, { useContext, createContext } from 'react';
import { useMediaQuery } from 'react-responsive';

const Context = createContext(null);
export const useMedia = () => useContext(Context);

const Provider = ({ children }) => {
  const isBigScreen = useMediaQuery({ minWidth: 1824 });
  const isDesktop = useMediaQuery({ minWidth: 1224 });
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isPortrait = useMediaQuery({ orientation: 'portrait' });
  const isRetina = useMediaQuery({ minResolution: '2dppx' });

  return (
    <Context.Provider
      value={{
        isBigScreen,
        isDesktop,
        isTabletOrMobile,
        isTablet,
        isMobile,
        isPortrait,
        isRetina,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Provider;
