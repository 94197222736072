import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useAuth } from 'providers';
import { routes } from 'config';

const Comp = ({ component: Component, ...rest }) => {
  const { isUser } = useAuth();
  const redirectLocation = rest.location;

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        isUser ? (
          <Component {...routeProps} />
        ) : (
          <Redirect
            to={{ pathname: routes.signIn, state: { redirectLocation } }}
          />
        )
      }
    />
  );
};

export default Comp;
