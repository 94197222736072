import React, { useCallback, useMemo, useState } from 'react';
import {
  Button,
  Dropdown,
  Icon,
  Item,
  List,
  Divider,
  Segment,
} from 'semantic-ui-react';
import { useQuery } from '@apollo/client';
import _ from 'lodash';

import { ADDRESSES_QUERY } from 'gql';

import NewAddressModal from './NewAddressModal';

const Comp = ({ selectedId, onSelect = () => null, autoOpenNew = false }) => {
  const [open, setOpen] = useState(false);

  const { data = {}, loading } = useQuery(ADDRESSES_QUERY);
  const { addresses = [] } = data;

  const selectedAddress = useMemo(
    () => addresses.find((obj) => obj.id.toString() === selectedId?.toString()),
    [addresses, selectedId]
  );

  const renderAddress = useCallback(
    (address) => {
      return (
        <Dropdown.Item
          key={address.id}
          selected={selectedAddress?.id?.toString() === address.id.toString()}
          onClick={() => onSelect(address)}
        >
          <Item.Group>
            <Item>
              <Item.Content className="textLeft">
                <Item.Meta>
                  <List horizontal size="small">
                    <List.Item>
                      <b>
                        <Icon name="user" /> {address.full_name}
                      </b>
                    </List.Item>
                    {/* <List.Item>
                      <b>
                        <Icon name="mail" /> {address.email}
                      </b>
                    </List.Item> */}
                    <List.Item>
                      <b>
                        <Icon name="phone" />{' '}
                        {address.phone_number?.intl_number}
                      </b>
                    </List.Item>
                  </List>
                </Item.Meta>
                <Item.Description>
                  {_.truncate(address.street_number, { length: 70 })}
                  <br />
                  {_.truncate(address.apartment_floor, { length: 70 })}
                  <Divider hidden fitted />
                  <small>
                    {address.city?.name} - {address.city?.country?.name}
                  </small>
                </Item.Description>
              </Item.Content>
            </Item>
          </Item.Group>
        </Dropdown.Item>
      );
    },
    [onSelect, selectedAddress?.id]
  );

  const truncatedAddress = _.truncate(selectedAddress?.street_number, {
    length: 35,
  });

  const selectedAddressText = selectedAddress?.full_name &&
    selectedAddress?.phone_number && (
      <Segment basic style={{ padding: '0' }}>
        <small>
          <b>
            <Icon name="user" />
            {selectedAddress.full_name} <Icon name="phone" />
            {selectedAddress.phone_number.intl_number}
          </b>
        </small>
        <Divider hidden fitted />
        <span>
          <b>{truncatedAddress}</b>
        </span>
      </Segment>
    );

  const onNewAddress = (address) => {
    setOpen(false);
    onSelect(address);
  };

  return (
    <>
      <Button.Group basic fluid size="big">
        <Dropdown
          text={selectedAddressText || 'Select Address'}
          scrolling
          labeled
          button
          fluid
          className="icon"
          loading={loading}
        >
          <Dropdown.Menu>
            <Dropdown.Header>
              <Button type="button" fluid onClick={() => setOpen(true)}>
                <Icon name="plus" /> Add new address
              </Button>
            </Dropdown.Header>

            <Dropdown.Divider />

            {addresses
              .filter((obj) => obj.id.toString() !== selectedId?.toString())
              .map(renderAddress)}
          </Dropdown.Menu>
        </Dropdown>
      </Button.Group>
      <NewAddressModal
        onNewAddress={onNewAddress}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        // defaultOpen={!addresses.length && autoOpenNew}
      />
    </>
  );
};

export default Comp;
