import { gql } from '@apollo/client';

export default gql`
  fragment AddressFragment on Address {
    id
    street_number
    apartment_floor
    full_name
    city {
      id
      name
      country {
        name
      }
    }
    phone_number {
      intl_number
    }
  }
`;
