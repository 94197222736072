import { gql } from '@apollo/client';

export default gql`
  query country($isoCode: String!) {
    country(iso_code: $isoCode) {
      id
      cities {
        id
        name
      }
    }
  }
`;
