import { gql } from '@apollo/client';
import EXO_ITEM_FRAGMENT from './exoItem';

export default gql`
  fragment ExoPackageFragment on ExoPackage {
    id
    items {
      ...ExoItemFragment
    }
    tracking
    arrivedAt
  }
  ${EXO_ITEM_FRAGMENT}
`;
