import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import {
  Header,
  Button,
  Container,
  Icon,
  Image,
  Grid,
  Divider,
} from 'semantic-ui-react';

import { MainLayout, CalculatorModal } from 'components';
import { useMedia } from 'providers';
import { routes } from 'config';

import logoIcon from 'assets/images/logoIcon.svg';
import atf from './assets/above_the_fold.png';
import hiw from './assets/how-it-works_1.png';
import step1 from './assets/step1.svg';
import step2 from './assets/step2.svg';
import step3 from './assets/step3.svg';
import arrow1 from './assets/arrow-1@4x-1.png';
import arrow2 from './assets/arrow-2@4x-1.png';
import icon1 from './assets/icon_1.svg';
import icon2 from './assets/icon_2.svg';
import icon3 from './assets/icon_3.svg';
import icon4 from './assets/icon_4.svg';

const Comp = () => {
  const { isTabletOrMobile, isMobile } = useMedia();

  return (
    <MainLayout>
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="facebook-domain-verification"
          content="gvik1sx50r9g9k5t0ldot0o07nezh1"
        />
        <title>Exohub: Ship with Confidence</title>
        <meta
          name="description"
          content="Exohub is a shipping service that makes it easy to buy products from USA, UK, UAE or China and ship them to any address in Egypt at competitive rates"
        />
      </Helmet>
      <div
        style={{
          // backgroundImage: `url(${bgImg})`,
          backgroundRepeat: 'no-repeat',
          minHeight: '100vh',
          paddingTop: '5rem',
          paddingBottom: '5rem',
        }}
      >
        {/* above the fold */}
        <Container>
          <Grid
            reversed="tablet computer"
            verticalAlign="middle"
            doubling
            stackable
          >
            <Grid.Row>
              <Grid.Column width={7}>
                <Image
                  verticalAlign="middle"
                  centered
                  src={atf}
                  style={{ maxWidth: '90%' }}
                />
              </Grid.Column>
              <Grid.Column width={9}>
                <Header
                  as="h1"
                  style={{
                    fontFamily: 'Recoleta Bold',
                    fontWeight: 700,
                    fontSize: isTabletOrMobile ? '1.7rem' : '2.5rem',
                  }}
                >
                  Shop from Any Online Store in USA, UK or UAE & Deliver
                  to Egypt
                </Header>

                <Header as="h2">
                  <Header.Subheader
                    style={{
                      fontSize: isTabletOrMobile ? '1.25rem' : '1.4rem',
                      lineHeight: isTabletOrMobile ? '1.5rem' : '2.0rem',
                    }}
                  >
                    Guaranteed Pricing for Shipping & Customs Clearance. No
                    Additional Fees on Delivery!
                  </Header.Subheader>
                </Header>

                <div style={{ height: '1rem' }} />

                <Button
                  size={isTabletOrMobile ? 'large' : 'big'}
                  primary
                  icon
                  labelPosition="right"
                  style={{ marginBottom: '0.5rem' }}
                  fluid={isMobile}
                  as={Link}
                  to={routes.signUp}
                >
                  Get Started
                  <Icon name="right arrow" />
                </Button>
                <Button
                  size={isTabletOrMobile ? 'large' : 'big'}
                  color="teal"
                  icon
                  labelPosition="right"
                  as={HashLink}
                  smooth
                  to="#howItWorks"
                  style={{ marginBottom: '0.5rem' }}
                  fluid={isMobile}
                >
                  How it Works
                  <Icon name="down arrow" />
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>

        {/* 1st section */}
        <Container
          textAlign="center"
          style={{
            background: '#fff',
            borderRadius: '40px',
            padding: isTabletOrMobile ? '4rem 1rem' : '4rem',
            marginTop: '6rem',
          }}
        >
          <Image alt="ExoHub" src={logoIcon} size="tiny" centered />
          <Header
            as="h2"
            style={{
              fontFamily: 'Recoleta Bold',
              fontWeight: 700,
              fontSize: isTabletOrMobile ? '1.75rem' : '2.5rem',
            }}
          >
            Cross-Border Shopping Made Easy
          </Header>

          <Container text>
            <Header>
              <Header.Subheader
                style={{
                  fontSize: isTabletOrMobile ? '1.25rem' : '1.4rem',
                  lineHeight: isTabletOrMobile ? '1.5rem' : '2rem',
                }}
              >
                By using our simple shipping calculator, you will know exactly
                how much it costs to import a product, including international
                shipping cost & customs duties.
              </Header.Subheader>
            </Header>
            <CalculatorModal
              trigger={
                <Button
                  size={isTabletOrMobile ? 'large' : 'big'}
                  color="black"
                  icon
                  labelPosition="right"
                  fluid={isMobile}
                >
                  Use Calculator
                  <Icon name="calculator" />
                </Button>
              }
            />
          </Container>
        </Container>

        {/* how it works */}
        <div id="howItWorks" style={{ paddingTop: '6rem' }}>
          <Container>
            <Grid stackable verticalAlign="middle" columns={2}>
              <Grid.Column>
                <Image src={hiw} fluid centered style={{ maxWidth: '500px' }} />
              </Grid.Column>

              <Grid.Column>
                <Header
                  as="h2"
                  style={{
                    fontFamily: 'Recoleta Bold',
                    fontWeight: 700,
                    fontSize: '2.5rem',
                    textAlign: isMobile ? 'center' : undefined,
                  }}
                >
                  How it Works
                </Header>

                <Header style={{ marginTop: 0 }}>
                  <Header.Subheader
                    style={{ fontSize: '1.4rem', lineHeight: '2rem' }}
                  >
                    Shop any online store and enter your Exohub address in the
                    checkout step. Once we receive your packages at our
                    warehouse, we will handle the international shipping,
                    customs clearance and deliver your products anywhere in
                    Egypt!
                  </Header.Subheader>
                </Header>
                <Divider hidden />
                <div style={{ textAlign: isMobile ? 'center' : undefined }}>
                  <Button
                    as={Link}
                    to={routes.signUp}
                    size="big"
                    primary
                    // inverted
                  >
                    Start Shipping Now
                  </Button>
                </div>
              </Grid.Column>
            </Grid>
          </Container>
        </div>

        {/* steps */}
        <Container style={{ marginTop: '6rem' }}>
          <Grid stackable columns={3}>
            {/* step1 */}
            <Grid.Column textAlign="center">
              <Image
                src={step1}
                fluid
                centered
                style={{
                  height: '130px',
                  width: 'auto',
                  maxWidth: '130px',
                  objectFit: 'contain',
                  objectPosition: '50% 20%',
                  marginBottom: '1rem',
                  // filter:
                  //   'invert(69%) sepia(30%) saturate(3730%) hue-rotate(184deg) brightness(97%) contrast(104%)',
                }}
              />

              <Header
                style={{
                  fontFamily: 'Recoleta Bold',
                  fontWeight: 700,
                  fontSize: '2rem',
                  margin: 0,
                }}
              >
                Get your Exohub address
              </Header>

              <Header>
                <Header.Subheader
                  style={{
                    fontSize: '1.2rem',
                    lineHeight: '2rem',
                    marginLeft: '2rem',
                    marginRight: '2rem',
                  }}
                >
                  Sign up for Exohub and get your personalized shipping address
                  in USA, China, UK & UAE.
                </Header.Subheader>
              </Header>

              {!isTabletOrMobile && (
                <Image
                  src={arrow2}
                  style={{
                    position: 'absolute',
                    top: '60px',
                    right: '-40px',
                    width: '110px',
                  }}
                />
              )}
            </Grid.Column>

            {/* step2 */}
            <Grid.Column textAlign="center">
              <Image
                src={step2}
                fluid
                centered
                style={{
                  height: '130px',
                  width: 'auto',
                  maxWidth: '130px',
                  objectFit: 'contain',
                  objectPosition: '50% 20%',
                  marginBottom: '1rem',
                  // filter:
                  //   'invert(69%) sepia(30%) saturate(3730%) hue-rotate(184deg) brightness(97%) contrast(104%)',
                }}
              />

              <Header
                style={{
                  fontFamily: 'Recoleta Bold',
                  fontWeight: 700,
                  fontSize: '2rem',
                  margin: 0,
                }}
              >
                Start shopping as you wish
              </Header>

              <Header>
                <Header.Subheader
                  style={{
                    fontSize: '1.2rem',
                    lineHeight: '2rem',
                    marginLeft: '2rem',
                    marginRight: '2rem',
                  }}
                >
                  At checkout, enter your personalized Exohub shipping address.
                </Header.Subheader>
              </Header>

              {!isTabletOrMobile && (
                <Image
                  src={arrow1}
                  style={{
                    position: 'absolute',
                    top: '60px',
                    right: '-40px',
                    width: '110px',
                  }}
                />
              )}
            </Grid.Column>

            {/* step3 */}
            <Grid.Column textAlign="center">
              <Image
                src={step3}
                fluid
                centered
                style={{
                  height: '130px',
                  width: 'auto',
                  maxWidth: '130px',
                  objectFit: 'contain',
                  objectPosition: '50% 20%',
                  marginBottom: '1rem',
                  // filter:
                  //   'invert(69%) sepia(30%) saturate(3730%) hue-rotate(184deg) brightness(97%) contrast(104%)',
                }}
              />

              <Header
                style={{
                  fontFamily: 'Recoleta Bold',
                  fontWeight: 700,
                  fontSize: '2rem',
                  margin: 0,
                }}
              >
                Receive all your packages
              </Header>

              <Header>
                <Header.Subheader
                  style={{
                    fontSize: '1.2rem',
                    lineHeight: '2rem',
                    marginLeft: '2rem',
                    marginRight: '2rem',
                  }}
                >
                  Exohub will ship, clear from customs and deliver your packages
                  to any address in Egypt.
                </Header.Subheader>
              </Header>
            </Grid.Column>
          </Grid>
        </Container>

        {/* values */}
        <Container
          style={{
            background: '#fff',
            borderRadius: '40px',
            padding: isTabletOrMobile ? '4rem 1rem' : '4rem',
            marginTop: '6rem',
          }}
        >
          <Header
            as="h2"
            style={{
              fontFamily: 'Recoleta Bold',
              fontWeight: 700,
              fontSize: '2.5rem',
              textAlign: 'center',
              marginBottom: '4rem',
            }}
          >
            Features You Will Love
          </Header>

          {/* row1 */}
          <Grid stackable doubling columns={4} style={{ marginBottom: '4rem' }}>
            {/* v1 */}
            <Grid.Column style={{ marginBottom: '2rem' }}>
              <div style={{ marginBottom: '1rem' }}>
                <Image
                  src={icon1}
                  fluid
                  style={{
                    height: '80px',
                    width: 'auto',
                    objectFit: 'contain',
                  }}
                />
              </div>

              <Header
                style={{
                  fontFamily: 'Recoleta Bold',
                  fontWeight: 700,
                  fontSize: '2rem',
                  margin: 0,
                }}
              >
                Simple Pricing
              </Header>

              <Header style={{ marginTop: '0.5rem' }}>
                <Header.Subheader
                  style={{
                    fontSize: '1.2rem',
                    lineHeight: '1.8rem',
                  }}
                >
                  Know exactly how much products will cost to import to Egypt
                </Header.Subheader>
              </Header>
            </Grid.Column>

            {/* v2 */}
            <Grid.Column style={{ marginBottom: '2rem' }}>
              <div style={{ marginBottom: '1rem' }}>
                <Image
                  src={icon2}
                  fluid
                  style={{
                    height: '80px',
                    width: 'auto',
                    objectFit: 'contain',
                  }}
                />
              </div>

              <Header
                style={{
                  fontFamily: 'Recoleta Bold',
                  fontWeight: 700,
                  fontSize: '2rem',
                  margin: 0,
                }}
              >
                Free Insurance
              </Header>

              <Header style={{ marginTop: '0.5rem' }}>
                <Header.Subheader
                  style={{
                    fontSize: '1.2rem',
                    lineHeight: '1.8rem',
                  }}
                >
                  Your products are fully insured against damage or loss.
                </Header.Subheader>
              </Header>
            </Grid.Column>

            {/* v3 */}
            <Grid.Column style={{ marginBottom: '2rem' }}>
              <div style={{ marginBottom: '1rem' }}>
                <Image
                  src={icon3}
                  fluid
                  style={{
                    height: '80px',
                    width: 'auto',
                    objectFit: 'contain',
                  }}
                />
              </div>

              <Header
                style={{
                  fontFamily: 'Recoleta Bold',
                  fontWeight: 700,
                  fontSize: '2rem',
                  margin: 0,
                }}
              >
                Convenience
              </Header>

              <Header style={{ marginTop: '0.5rem' }}>
                <Header.Subheader
                  style={{
                    fontSize: '1.2rem',
                    lineHeight: '1.8rem',
                  }}
                >
                  See photos of all your products when they are received at
                  Exohub's warehouse.
                </Header.Subheader>
              </Header>
            </Grid.Column>

            {/* v4 */}
            <Grid.Column style={{ marginBottom: '2rem' }}>
              <div style={{ marginBottom: '1rem' }}>
                <Image
                  src={icon4}
                  fluid
                  style={{
                    height: '80px',
                    width: 'auto',
                    objectFit: 'contain',
                  }}
                />
              </div>

              <Header
                style={{
                  fontFamily: 'Recoleta Bold',
                  fontWeight: 700,
                  fontSize: '2rem',
                  margin: 0,
                }}
              >
                Online Tracking
              </Header>

              <Header style={{ marginTop: '0.5rem' }}>
                <Header.Subheader
                  style={{
                    fontSize: '1.2rem',
                    lineHeight: '1.8rem',
                  }}
                >
                  Track your packages once they are received at our warehouse,
                  till they are delivered to you.
                </Header.Subheader>
              </Header>
            </Grid.Column>
          </Grid>

          <div style={{ textAlign: 'center' }}>
            <Button
              size={isTabletOrMobile ? 'large' : 'big'}
              primary
              icon
              labelPosition="right"
              as={Link}
              to={routes.signUp}
              fluid={isMobile}
            >
              Get Started
              <Icon name="right arrow" />
            </Button>
          </div>
        </Container>
      </div>
    </MainLayout>
  );
};

export default Comp;
