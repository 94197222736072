import React from 'react';
import { useQuery } from '@apollo/client';
import { Card, Segment, Header, Button, Container } from 'semantic-ui-react';

import { DashboardLayout, OrderCard } from 'components';
import { ORDERS_QUERY } from 'gql';

const PER = 10;

const Comp = () => {
  const { data = {}, loading, fetchMore } = useQuery(ORDERS_QUERY, {
    variables: { per: PER },
  });
  const { exoOrders = {} } = data;
  const { nodes = [], page, per, total } = exoOrders;

  const isNextPage = total > per * page;

  return (
    <DashboardLayout
      subMenu={<Header>Orders History</Header>}
      loading={loading}
    >
      <Container text>
        <Card.Group itemsPerRow={1} stackable>
          {nodes.map((order) => (
            <OrderCard key={order.id} order={order} />
          ))}
          {!nodes.length && (
            <div
              style={{ textAlign: 'center', width: '100%', marginTop: '100px' }}
            >
              <p>You do not have orders yet.</p>
            </div>
          )}
        </Card.Group>

        {isNextPage && (
          <Segment basic padded="very" textAlign="center">
            <Button
              size="large"
              onClick={() =>
                fetchMore({
                  variables: { per: PER, page: page + 1 },
                })
              }
            >
              Load more
            </Button>
          </Segment>
        )}
      </Container>
    </DashboardLayout>
  );
};

export default Comp;
