import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment,
} from 'semantic-ui-react';

import { useError } from 'hooks';
import { useAuth } from 'providers';
import { routes } from 'config';
import { RESET_PASSWORD_MUTATION } from 'gql';

import logoImg from 'assets/images/logo-blue.svg';

const Comp = () => {
  const { signIn } = useAuth();

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const otp = params.get('otp') || '';

  const { redirectLocation } = location.state || {
    redirectLocation: { pathname: routes.signedInHome },
  };

  const [resetPasswordOTP, setOTP] = useState(otp);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const isMatchError =
    !!password && !!passwordConfirmation && password !== passwordConfirmation;

  const [mtnFnc, { loading, error }] = useMutation(RESET_PASSWORD_MUTATION, {
    onError: () => null,
    variables: { otp: resetPasswordOTP, password },
    onCompleted: ({ exoResetPassword }) => {
      signIn(exoResetPassword, () => history.replace(redirectLocation));
    },
  });

  const errorMsg = useError(error);

  return (
    <Grid centered style={{ height: '100vh' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <div style={{ textAlign: 'center' }}>
          <Link to="/">
            <Image verticalAlign="middle" centered src={logoImg} />
          </Link>
        </div>
        <Header as="h2" textAlign="center">
          Reset your password
        </Header>
        <Message style={{ textAlign: 'center' }}>
          Check your email inbox. You will receive an email with a one time
          passcode <b>(OTP)</b> to be able to reset your password.
        </Message>
        <Form
          size="large"
          loading={loading}
          error={!!errorMsg}
          onSubmit={mtnFnc}
        >
          <Segment stacked>
            <Form.Input
              required
              fluid
              icon="key"
              iconPosition="left"
              label="OTP"
              placeholder="OTP"
              type="text"
              value={resetPasswordOTP}
              onChange={(e) => setOTP(e.target.value)}
            />
            <Form.Input
              required
              fluid
              icon="lock"
              iconPosition="left"
              label="New Password"
              placeholder="New Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Form.Input
              required
              fluid
              icon="lock"
              iconPosition="left"
              label="Re-enter New Password"
              placeholder="Re-enter New Password"
              type="password"
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              error={isMatchError}
            />

            <Message error content={errorMsg} />

            <Button primary fluid size="large">
              Save New Password
            </Button>
          </Segment>
        </Form>
        <Message style={{ textAlign: 'center' }}>
          Remember your password?{' '}
          <Link to={{ pathname: routes.signIn, state: { redirectLocation } }}>
            Sign In
          </Link>
        </Message>
      </Grid.Column>
    </Grid>
  );
};

export default Comp;
