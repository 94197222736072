import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Header,
  Menu,
  Segment,
  Dropdown,
  Icon,
  Grid,
  Image,
  Visibility,
  Form,
} from 'semantic-ui-react';

import { useAuth } from 'providers';
import { routes, asCurrency } from 'config';

import logoImg from 'assets/images/logo-blue.svg';

import IdUploader from './IdUploader';

const countries = [
  {
    key: 'US',
    text: 'US',
    value: 'US',
    flag: 'us',
  },
  {
    key: 'UK',
    text: 'UK',
    value: 'UK',
    flag: 'uk',
  },
  // {
  //   key: 'AE',
  //   text: 'AE',
  //   value: 'AE',
  //   flag: 'ae',
  // },
  // {
  //   key: 'CN',
  //   text: 'CN',
  //   value: 'CN',
  //   flag: 'cn',
  // },
];

const types = [
  {
    key: 'Standard',
    text: 'Standard',
    value: 'Standard',
  },
  {
    key: 'Economy',
    text: 'Economy',
    value: 'Economy',
  },
  // {
  //   key: 'Express',
  //   text: 'Express',
  //   value: 'Express',
  // },
];

const LayoutHeader = ({ subMenu }) => {
  const { user, wallet } = useAuth();

  const [isSticked, setIsSticked] = useState(false);
  const [sourceCountry, setSourceCountry] = useState(countries[0].value);
  const [shippingType, setShippingType] = useState(types[0].value);

  const address = user.exohubInventories.find(
    (e) => e.shippingType === shippingType && e.countryIsoCode === sourceCountry
  );

  const stick = () => !!subMenu && setIsSticked(true);
  const unstick = () => !!subMenu && setIsSticked(false);

  const renderSubMenue = (
    <Segment
      style={{
        marginTop: 0,
        minHeight: '2rem',
        borderRadius: '0.5rem 0.5rem 0 0',
        border: 'none',
        boxShadow: 'none',
      }}
    >
      {subMenu}
    </Segment>
  );

  return (
    <>
      <Visibility
        once={false}
        onBottomPassed={stick}
        onBottomPassedReverse={unstick}
      >
        <Grid columns="equal" stretched stackable>
          <Grid.Column width={3} verticalAlign="middle" textAlign="center">
            <Link to="/">
              <Image
                verticalAlign="middle"
                centered
                src={logoImg}
                style={{ height: '2.5rem', marginTop: '4px' }}
              />
            </Link>
          </Grid.Column>
          <Grid.Column>
            <Segment basic>
              <Header size="medium">
                <Header.Content>
                  <Icon name="warehouse" /> Your Shipping Address
                  {user.exohubInventoryId && (
                    <>
                      <Form size="small" style={{ marginTop: '0.5rem' }}>
                        <Form.Group widths="equal">
                          <Form.Select
                            label="Source Country"
                            options={countries}
                            value={sourceCountry}
                            onChange={(e, { value }) => setSourceCountry(value)}
                            style={{ zIndex: 15 }}
                            scrolling={false}
                          />
                          <Form.Select
                            label="Shipping Type"
                            options={types}
                            value={shippingType}
                            onChange={(e, { value }) => setShippingType(value)}
                            scrolling={false}
                          />
                        </Form.Group>
                      </Form>
                      <Header.Subheader
                        style={{ lineHeight: '1.8rem', marginTop: '0.5rem' }}
                      >
                        Street address line one:&nbsp;
                        <b>{address.line1}</b>
                        <br />
                        Street address line two:&nbsp;
                        <a
                          style={{
                            color:
                              shippingType === 'Express'
                                ? '#db2828'
                                : shippingType === 'Economy'
                                ? '#21ba45'
                                : '#3269ff',
                          }}
                        >
                          <b>{address.line2}</b>
                        </a>
                        <br />
                        {address.areaName && (
                          <>
                            Area: <b>{address.areaName}</b>
                            <span
                              style={{ width: '10px', display: 'inline-block' }}
                            />
                          </>
                        )}
                        City:&nbsp;
                        <b>{address.cityName}</b>
                        {address.stateName && (
                          <>
                            <span
                              style={{ width: '10px', display: 'inline-block' }}
                            />
                            State: <b>{address.stateName}</b>
                          </>
                        )}
                        {address.zipCode && (
                          <>
                            <span
                              style={{ width: '10px', display: 'inline-block' }}
                            />
                            Zip Code: <b>{address.zipCode}</b>
                          </>
                        )}
                        {address.phoneNumber && (
                          <>
                            <br />
                            Phone: <b>{address.phoneNumber}</b>
                          </>
                        )}
                      </Header.Subheader>
                    </>
                  )}
                </Header.Content>
              </Header>
              <IdUploader />
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment basic>
              <Header size="medium">
                <Header.Content>
                  <Icon name="credit card outline" /> Wallet Balance
                  <Header.Subheader
                    style={{
                      lineHeight: '1.5rem',
                      marginTop: '0.5rem',
                      marginBottom: '0.5rem',
                    }}
                  >
                    <h1>{asCurrency(wallet)} EGP</h1>
                  </Header.Subheader>
                  {/* <Button basic color="blue" compact size="tiny">
                    Add Funds
                  </Button> */}
                </Header.Content>
              </Header>
            </Segment>
          </Grid.Column>
          <Grid.Column verticalAlign="middle" width={3}>
            <Menu secondary>
              <Menu.Menu position="right">
                <Dropdown
                  pointing
                  item
                  trigger={
                    <span style={{ minWidth: '80px' }}>
                      <Icon size="big" name="user circle" />
                      {user.full_name}
                    </span>
                  }
                >
                  <Dropdown.Menu>
                    {/* <Dropdown.Item
                      as={Link}
                      text="Settings"
                      to={routes.settings}
                    /> */}
                    <Dropdown.Item as={Link} text="Orders" to={routes.orders} />
                    <Dropdown.Divider />
                    <Dropdown.Item
                      as={Link}
                      text="Sign Out"
                      to={routes.signOut}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </Menu.Menu>
            </Menu>
          </Grid.Column>
        </Grid>
      </Visibility>

      <div
        style={{
          backgroundColor: '#F3F6FB',
          paddingTop: '0.4rem',
          paddingRight: '0.4rem',
          paddingLeft: '0.4rem',
          left: 0,
          right: 0,
          zIndex: 10,
          position: isSticked ? 'fixed' : 'absolute',
          top: isSticked ? 0 : undefined,
          marginTop: isSticked ? 0 : '1rem',
          boxShadow: isSticked
            ? 'rgba(0, 0, 0, 0.5) 0px 7px 14px -7px'
            : undefined,
        }}
      >
        {renderSubMenue}
      </div>

      <div style={{ paddingTop: '1rem' }}>{renderSubMenue}</div>
    </>
  );
};

export default LayoutHeader;
