import React, { Suspense, useRef } from 'react';
import { Segment, Header, Image, Icon, Transition } from 'semantic-ui-react';

import creditCardImg from 'assets/images/creditCard.png';
import bankDepositImg from 'assets/images/bankDeposit.png';
import bostaImg from 'assets/images/bosta.svg';
// import walletImg from 'assets/images/wallet.png';

const methodsData = {
  CreditCard: {
    name: 'Credit Card',
    img: creditCardImg,
    Component: React.lazy(() => import('./CreditCard')),
  },
  BankDeposit: {
    name: 'Bank Deposit',
    img: bankDepositImg,
    Component: React.lazy(() => import('./BankDeposit')),
  },
  CashOnDelivery: {
    name: 'Cash on Delivery',
    img: bostaImg,
    Component: React.lazy(() => import('./COD')),
  },
  Wallet: {
    name: 'Wallet',
    // img: walletImg,
    Component: React.lazy(() => import('./Wallet')),
  },
};

const Comp = ({
  method,
  exoCheckout,
  isSelected,
  error,
  onSelect = () => null,
  completeOrderBtn = () => null,
  completeOrderHandler = () => null,
}) => {
  const { name, img, Component } = methodsData[method] || {};

  const getPaymentMethodDataRef = useRef();

  const onSubmit = () => {
    completeOrderHandler(getPaymentMethodDataRef.current);
  };

  if (!Component) return null;

  return (
    <Segment.Group>
      <Segment
        secondary={!isSelected}
        onClick={() => onSelect(method)}
        style={{ cursor: 'pointer' }}
      >
        <Header size="medium">
          {img && (
            <Image
              src={img}
              size="tiny"
              floated="right"
              style={{ height: '2.2em', width: 'auto' }}
            />
          )}

          {isSelected ? (
            <Icon color="green" name="check circle" size="big" />
          ) : (
            <Icon color="grey" name="circle outline" size="big" />
          )}

          <Header.Content>{name}</Header.Content>
        </Header>
      </Segment>

      <Transition
        unmountOnHide={true}
        visible={isSelected}
        animation={isSelected ? 'fade down' : 'fade up'}
        duration={isSelected ? 500 : 0}
      >
        <>
          <Segment padded>
            <Suspense fallback={<div>Loading...</div>}>
              <Component
                exoCheckout={exoCheckout}
                getDataHandler={(handler) =>
                  (getPaymentMethodDataRef.current = handler)
                }
                error={error}
              />
            </Suspense>
          </Segment>
          <Segment padded>{completeOrderBtn(onSubmit)}</Segment>
        </>
      </Transition>
    </Segment.Group>
  );
};

export default Comp;
